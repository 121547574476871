import { useState, useEffect } from "react";
import "./Betslip.css";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { purchaseTicket, addPlayerBet } from "../../Api";
import axios from "axios";
import * as actions from "../../store/actions";
import Loader from "../Loader/Loader";
import { Link } from "react-router-dom";

function Betslip(props) {
    const [loader, setLoader] = useState(null);
    const [wrappersts, setWrappersts] = useState("openbetslip");
    const [btslip, setBetslip] = useState("");
    const [stake, setStake] = useState("");
    const [minimumstake, setMinimumstake] = useState("");
    const [popupsts, setpopupsts] = useState("");
    const [popupsave, setpopupsave] = useState("");
    const [allBetPopup, setAllBetPopup] = useState("");
    const [loginPopup, setLoginPopup] = useState("");
    const [pooltitle, setpooltitle] = useState("");
    const [matches, setmatches] = useState(props.poolItem);
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [finalSelected, setFinalSelected] = useState([]);
    const [bets, setBets] = useState([]);
    const [betObject, setBetObject] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [betmess, setBetmess] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState("false");
    const [activeBtnsCount, setActiveBtnsCount] = useState(0);
    const [betreference, setBetReference] = useState(null);
    const [searchMessage, setSearchMessage] = useState("");

    useEffect(() => {
        document.body.classList.remove("header-open");
    }, []);

    useEffect(() => {
        setIsLoggedIn(localStorage.getItem("IsLoggedIn"));
    }, [localStorage.getItem("IsLoggedIn")]);

    useEffect(() => {
        if (props.poolItem) {
            setpooltitle(props.poolItem.pool_title);
            setmatches(props.poolItem.pool_nb_matches);
            props.poolItem.pool_ticket_price
                ? setMinimumstake(props.poolItem.pool_ticket_price)
                : setMinimumstake("");
            props.poolItem.pool_ticket_price
                ? setStake(props.poolItem.pool_ticket_price)
                : setStake("");
        }
    }, [props.poolItem]);

    useEffect(() => {
        if (stake === "" && props?.poolItem?.pool_ticket_price) {
            setStake(props.poolItem.pool_ticket_price);
            setmatches(props.poolItem.pool_nb_matches);
            props.poolItem.pool_ticket_price
                ? setMinimumstake(props.poolItem.pool_ticket_price)
                : setMinimumstake("");
        }

        let count = 0;

        if (props.activeButtonsList) {
            let selectedIndex = [];
            let selectedIndexString = "";
            let matchSplit = [];
            let betDetails = {};
            let matchId;

            let tempBet = [];
            props.activeButtonsList.map((match, matchIndex) => {
                match.map((option, optionIndex) => {
                    if (option !== "") {
                        selectedIndex.push(optionIndex + 1);
                        matchId = option;

                        count++;
                    }
                });
                selectedIndexString = [...selectedIndex].join(",");
                selectedIndex = [];
                if (selectedIndexString != "") {
                    matchSplit.push(selectedIndexString);
                }
                betDetails = {
                    choice: selectedIndexString,
                    matchOrder: matchIndex,
                    matcheId: matchId,
                };
                if (betDetails.choice != "")
                    tempBet.push(betDetails);
            });

            setBets(tempBet);

            if (minimumstake) {
                setStake(minimumstake);
            }
            setFinalSelected(matchSplit);
            setSelectedOptions([...matchSplit].join("/"));
            setActiveBtnsCount(count);
        }
    }, [props.activeButtonsList]);

    useEffect(() => {
        // Disable and Enable Place Bet button
        if (props.poolItem) {
            bets.length == props.poolItem.pool_nb_matches
                ? setDisabled(false)
                : setDisabled(true);
        }
    }, [bets]);

    useEffect(() => {
        let tempStake;
        tempStake = stake;
        finalSelected.map((match) => {
            tempStake = tempStake * match.split(",").length;
        });
        if (tempStake) {
            setStake(tempStake);
        }

        if (props.poolItem) {
            setBetObject({
                bets: bets,
                isDirect: 1,
                poolId: props.poolItem.pool_id,
                ticketPrice: tempStake,
                userToken: localStorage.getItem("token"),
            });
        }
    }, [finalSelected]);

    const Handlests = () => {
        if (wrappersts === "") {
            setWrappersts("openbetslip");
            setBetslip("");
            document.body.classList.remove("header-open");
        } else {
            setWrappersts("");
            setBetslip("hidecontainer");
            document.body.classList.add("header-open");
        }
    };

    const onLogin = () => {
        document.body.classList.remove("header-open");
    };

    const eraseBetslip = () => {
        setStake(minimumstake);
        setSelectedOptions(null);
        setActiveBtnsCount(0);
        props.updateActiveButtons(null);
        setBets([]);
    };

    const takeBet = () => {
        let url = purchaseTicket;
        setLoader(Loader);
        axios
            .post(url, betObject)
            .then((response) => {
                if (response.data.status == 0) {
                    setBetmess("Please check your phone to authorize payment!");
                    setTimeout(() => {
                        setpopupsts("");
                        eraseBetslip();
                        window.location.href = "/";
                    }, 3000);
                }
                setLoader(null);
            })
            .catch((e) => {
                setBetmess(e.message.details);
                setLoader(null);
            });
    };

    const saveBet = () => {
        let url = addPlayerBet;
        setLoader(Loader);
        axios
            .post(url, betObject)
            .then((response) => {
                if (response.data.status == 0) {
                    setBetmess("Bet saved successfuly!");
                    setTimeout(() => {
                        setpopupsave("");
                        eraseBetslip();
                        window.location.href = "/";
                    }, 3000);
                }
                setLoader(null);
            })
            .catch((e) => {
                setBetmess(e.message.details);
                setLoader(null);
            });
    };

    const searchBet = (e) => {
        e.preventDefault();
        props.isAuthenticated ?
            betreference?.substring(0, 3) === "GFA" ?
                props.searchRef(betreference)
                : setSearchMessage("Invalid booking code")
            : setLoginPopup("popupvisible");
    };

    return (
        <div className={"betslip " + wrappersts}>
            <div className="betslip__hdr-wrapper">
                <div className="hdr-wrapper-betsliptitle">
                    bet slip
                    <div className="betsliptitle-matchcount">
                        <span className="matchcount__count">{activeBtnsCount}</span>
                    </div>
                </div>
                <div className="hdr-wrapper__containeract" onClick={Handlests} />
                <FontAwesomeIcon
                    className="hdr-wrapper__betslipclear"
                    icon={faTrashCan}
                    onClick={eraseBetslip}
                />
            </div>
            <div className="betslip__bdy-wrapper">
                <label className="bdy-wrapper-searchlabel">
                    Booking Code
                </label>
                <form className="bdy-wrapper__searchform" onSubmit={searchBet}>
                    <input
                        type="text"
                        placeholder="Search.."
                        className="bdy-wrapper__searchfield"
                        onChange={(e) => {
                            setBetReference(e.target.value);
                            setSearchMessage(null);
                        }}
                    />
                    <button type="submit" className="bdy-wrapper-searchbtn">
                        <i className="fa fa-search"></i>
                    </button>
                </form>
                <p className="bdy-wrapper__searchMsg">{searchMessage}</p>
                {stake != "" ? (
                    <div className="bdy-wrapper-pools">
                        <span className="pools__totallabel">Stake Amount</span>
                        <div className="pools-pricewrapper">
                            <span className="pricewrapper__totalpool">ghc {stake}</span>
                        </div>
                        <span className="pools__minimumbet">
                            {minimumstake == "" ? "" : "Minimum bet ghc"} {minimumstake}
                        </span>
                    </div>
                ) : (
                    <></>
                )}
                {pooltitle ? (
                    <div>
                        <div className="bdy-wrapper-hrzline">
                            <i className="hrzline__line"></i>
                        </div>
                        <div className="bdy-wrapper-details">
                            <div className="details-wrapper">
                                <span className="details-wrapper__info">pool</span>
                            </div>
                            <div className="details-wrapper">
                                <span className="details-wrapper__info">{pooltitle}</span>
                            </div>
                        </div>
                        <div className="bdy-wrapper-details">
                            <div className="details-wrapper">
                                <span className="details-wrapper__info">runners</span>
                            </div>
                            <div className="details-wrapper">
                                <span className="details-wrapper__info">{selectedOptions}</span>
                            </div>
                        </div>

                        <div className="bdy-wrapper-btncontainer">
                            <button
                                className="btncontainer__btn"
                                onClick={() =>
                                    isLoggedIn == "true"
                                        ? disabled
                                            ? setAllBetPopup("popupvisible")
                                            : setpopupsts("popupvisible")
                                        : setLoginPopup("popupvisible")
                                }
                            >
                                place bet
                            </button>
                            <button
                                className="btncontainer__btn savebet-btn"
                                onClick={() =>
                                    isLoggedIn == "true"
                                        ? disabled
                                            ? setAllBetPopup("popupvisible")
                                            : setpopupsave("popupvisible")
                                        : setLoginPopup("popupvisible")
                                }
                            >
                                save bet
                            </button>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>

            <div className={"popup-container " + popupsts}>
                <div className="popup-container-titlewrapperpopup">
                    <span className="titlewrapperpopup__title">are you sure?</span>
                </div>
                <div className="popup-container-data">
                    <p className="popup-container-data__betdata">
                        You want to take this bet on {pooltitle} ({matches}) for GHC {stake}{" "}
                        <br /> {selectedOptions}
                    </p>
                </div>
                <div className="popup-container-actions">
                    <button
                        className="popup-container-actions__btnaction placebetbtn"
                        onClick={() => takeBet()}
                    >
                        take bet
                    </button>
                    <button
                        className="popup-container-actions__btnaction cancelbetbtn"
                        onClick={() => {
                            setpopupsts("");
                            setBetmess("");
                        }}
                    >
                        cancel
                    </button>
                </div>
                <div
                    className={
                        "popup-container-message " +
                        (betmess == "Please check your phone to authorize payment!"
                            ? ""
                            : "popup-container-message-error")
                    }
                >
                    {loader}
                    {betmess ? betmess : ""}
                </div>
            </div>
            <div className={"popup-container " + popupsave}>
                <div className="popup-container-titlewrapperpopup">
                    <span className="titlewrapperpopup__title">are you sure?</span>
                </div>
                <div className="popup-container-data">
                    <p className="popup-container-data__betdata">
                        You want to save this bet on {pooltitle} ({matches}) for GHC {stake}{" "}
                        <br /> {selectedOptions}
                    </p>
                </div>
                <div className="popup-container-actions">
                    <button
                        className="popup-container-actions__btnaction placebetbtn"
                        onClick={() => saveBet()}
                    >
                        save bet
                    </button>
                    <button
                        className="popup-container-actions__btnaction cancelbetbtn"
                        onClick={() => {
                            setpopupsave("");
                            setBetmess("");
                        }}
                    >
                        cancel
                    </button>
                </div>
                <div
                    className={
                        "popup-container-message " +
                        (betmess == "Bet saved successfuly!"
                            ? ""
                            : "popup-container-message-error")
                    }
                >
                    {loader}
                    {betmess ? betmess : ""}
                </div>
            </div>

            <div className={"popup-container " + allBetPopup}>
                <div className="popup-container-titlewrapperpopup">
                    <span className="titlewrapperpopup__title">Bet all matches</span>
                </div>
                <div className="popup-container-data">
                    <p className="popup-container-data__betdata">
                        Please bet on all matches to proceed!
                    </p>
                </div>
                <div className="popup-container-actions">
                    <button
                        className="popup-container-actions__btnaction placebetbtn"
                        onClick={() => setAllBetPopup("")}
                    >
                        OK
                    </button>
                </div>
            </div>

            <div className={"popup-container " + loginPopup}>
                <div className="popup-container-titlewrapperpopup">
                    <span className="titlewrapperpopup__title">Not Logged In</span>
                </div>
                <div className="popup-container-data">
                    <p className="popup-container-data__betdata">
                        Please login to place a bet!
                    </p>
                </div>
                <div className="popup-container-actions">
                    <Link
                        to="/login"
                        className="popup-container-actions__btnaction placebetbtn"
                        onClick={onLogin}
                    >
                        Login
                    </Link>
                    <button
                        className="popup-container-actions__btnaction cancelbetbtn"
                        onClick={() => {
                            setpopupsts("");
                            setBetmess("");
                            setLoginPopup("");
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>

            <div
                className={
                    popupsts === "popupvisible" ||
                        allBetPopup === "popupvisible" ||
                        loginPopup === "popupvisible" ||
                        popupsave === "popupvisible"
                        ? "popup-container-overlay"
                        : "popup-container-overlay-none"
                }
            />
            <div className={"betslip-action " + btslip} onClick={Handlests}>
                <div className="action-matchcount">
                    <span className="matchcount__nmbr">{activeBtnsCount}</span>
                </div>
                <div className="wrapper__lbl">betslip</div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null,
        activeButtonsList: state.auth.activeButtonsList,
        balance: state.auth.wallet,
        safety: state.auth.safety,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateActiveButtons: (btn_active) =>
            dispatch(actions.updateActiveButtons(btn_active)),
        getWallet: () => dispatch(actions.getWallet()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Betslip);
