import * as actionTypes from './actionTypes';
import axios from 'axios';
import { registerApi, userApi, verifyMsisdnApi, wallet } from '../../Api';

export const updateActiveButtons = (btn_active) => {
    return {
        type: actionTypes.UPDATE_ACTIVE_BUTTONS,
        activeButtonsList: btn_active
    }
};

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        isAuthenticated: true
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const authVerStart = () => {
    return {
        type: actionTypes.VER_START
    };
};

export const authVerFail = (message) => {
    return {
        type: actionTypes.VER_FAIL,
        vererror: message
    };
};

export const authRegFail = (error) => {
    return {
        type: actionTypes.AUTH_REG_FAIL,
        regerror: error
    };
};

export const authLogout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const regStart = () => {
    return {
        type: actionTypes.REG_START
    };
};

export const redirectVerify = (redirect) => {
    return {
        type: actionTypes.REDIRECT_VERIFY,
        redirect: redirect
    }
};

export const verifySuccess = (token) => {
    return {
        type: actionTypes.VERIFY_SUCCESS,
        token: token
    }
};

export const registerSuccess = (verify) => {
    return {
        type: actionTypes.REGISTER_SUCCESS,
        verify: verify
    };
};

export const updateWallet = (wallet, promoWallet) => {
    return {
        type: actionTypes.UPDATE_WALLET,
        wallet: wallet,
        promoWallet: promoWallet
    };
};

export const updateMessage = (message, status) => {
    return {
        type: actionTypes.UPDATE_MESSAGE,
        ticketMessage: message,
        status: status
    };
};

export const auth = (username, password) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            password: password,
            type: 1
        };
        let url = '/user-api/users/' + username;

        axios.post(url, authData)
            .then(response => {
                if (response?.data?.status === 1) {
                    localStorage.setItem("IsLoggedIn", "true");
                    localStorage.setItem("setUserData", JSON.stringify(response?.data?.user));
                    localStorage.setItem('token', response?.data?.token);
                    localStorage.setItem('msisdn', response?.data?.user?.mobileNumber?.value);
                    dispatch(redirectVerify(false));
                    dispatch(authSuccess(response.data.token));
                    dispatch(getWallet());
                }

                else if (response?.data?.status === 3) {
                    localStorage.setItem('unverified_token', response?.data?.token);
                    localStorage.setItem('unverified_msisdn', response?.data?.user?.mobileNumber?.value);
                    dispatch(redirectVerify(true));
                }
                else {
                    dispatch(authFail(response?.data?.message));
                }

            })
            .catch(err => {
            });
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        let url = userApi(token);
        if (token) {
            axios.get(url)
                .then(response => {
                    if (response.data.status == 1) {
                        dispatch(authSuccess(token));
                    }
                    else if (response.data.status == 0 || response.data.status == 4) {
                        dispatch(logout());
                    }
                })
                .catch(function (error) {
                    // console.log(error);
                });
        }
        else {
            // dispatch(logout());
        }
    };
};

export const logout = () => {

    return dispatch => {
        let token = localStorage.getItem('token');
        let url = userApi(token);

        axios.delete(url)
            .then(response => {
                localStorage.setItem("IsLoggedIn", "false");
                localStorage.removeItem("setUserData");
                localStorage.removeItem('token');
                localStorage.removeItem('msisdn');
                localStorage.removeItem('Balance');
                localStorage.removeItem('balance');
                dispatch(authLogout());
                window.location.reload(false);
                window.location.href = '/';
            })
            .catch(err => {
                dispatch(authLogout());
            });
    };
};

export const verifyMsisdn = (verificationCode) => {
    let token = localStorage.getItem('unverified_token');
    if (!token) {
        token = null;
    }
    let msisdn = localStorage.getItem('unverified_msisdn');
    return dispatch => {
        const authData = {
            mobileNumber: msisdn,
            code: verificationCode,
            type: 1,
        };
        let url = verifyMsisdnApi(token);
        dispatch(authVerStart());
        axios.patch(url, authData)
            .then(response => {
                if (response.data.status == 0) {
                    dispatch(authVerFail(response.data.message));
                } else {
                    if (response.data.status == 1) {

                        localStorage.removeItem('unverified_msisdn');
                        localStorage.removeItem('unverified_token');
                        localStorage.setItem("IsLoggedIn", "true");
                        localStorage.setItem("setUserData", JSON.stringify(response?.data?.user));
                        localStorage.setItem('msisdn', msisdn);
                        localStorage.setItem('token', response?.data?.token);
                        dispatch(verifySuccess(response.data.token));
                        dispatch(authSuccess(response.data.token));
                        dispatch(getWallet());
                        window.location.href = '/';
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    };
};

export const register = (
    firstName,
    lastName,
    mobileNumber,
    password,
    confirmPassword,
    operator) => {
    return dispatch => {
        dispatch(regStart());

        const authData = {
            firstName: firstName,
            lastName: lastName,
            mobileNumber: mobileNumber,
            email: null,
            password: password,
            confirmPassword: confirmPassword,
            operator: operator,
        };
        let url = registerApi;

        axios.post(url, authData)
            .then(response => {
                if (response?.data?.status === 1) {

                    localStorage.setItem('unverified_msisdn', mobileNumber);
                    localStorage.setItem('unverified_token', response.data.token);
                    localStorage.setItem("token", response?.data?.token);
                    window.location.href = '/verification';
                    dispatch(registerSuccess(true));
                }
                else {
                    dispatch(authRegFail(response.data.message));
                }

            })
            .catch(err => {
                // console.log(err);
            });
    };
};

export const getWallet = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        let url = wallet(token);

        axios.get(url)
            .then(response => {
                if (response.data.status == 1) {
                    localStorage.setItem('balance', response.data.amountFormatted)
                    localStorage.setItem('balanceamount', response.data.amount)
                    dispatch(updateWallet(response.data.amountFormatted, response.data.promotionAmountFormatted));

                } else {
                    dispatch(updateMessage(response.data.message));
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    };
};