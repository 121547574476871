import { useEffect } from "react";
import ReactGA from "react-ga4";

const Tnc = () => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/tnc" });
    }, [])

    return (<>
        <h3>Terms & Conditions </h3>
        <div className="l-page-content">



            <p>All Footy Cash pools, are hosted by KGL in partnership with the GFA and host rules apply as set out below.
            </p>
            <p>1A. IN ALL POOLS THE FOLLOWING ORDER WILL APPLY:</p>
            <p> Team A will be the team playing at “Home”.

                Team B will be the team playing “Away”.</p>
            <p> EXCEPTION: </p>
            <p>Where the match is being played at a neutral venue the order is of no consequence

            </p>
            <p>1.1 FANTASTIC 4 POOL

            </p>
            <p>1.1.1 PURPOSE:</p>

            <p>The purpose of the soccer 4, is to select the result of four soccer matches as designated in the official soccer 4 fixtures card for each Event.

                There are six possible results for each match via, 1 - Team A to win by 1 goal, 2 - Team A to win by 2 or more goals, 3 – a no score draw (0-0 only), 4 – a score draw (example 1-1; 2-2) 5 - Team B to win by 1 goal and 6 - Team B to win by 2 or more goals.

            </p>
            <p>On the USSD, *961#, there are 3 possible results for each match, HW, D or AW
                1.2.2 UNIT OF BETTING AND MINIMUM BET:

                Bet amount shall be pre-determined by the operator and user may stake multiple bets
            </p>
            <p>1.2.1 RESULTS:

                1.2.3.1 The result of a match shall be the score at the end of the normal 90-minute playing period (40-minutes in the case of Futsal) plus any referee’s optional time that may be played. It shall not include extra time nor penalty shootouts. </p>
            <p>1.2.3.2 The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g., PSL Press Association and other designated official sources. 1.2.3.3 In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator was not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and for purposes of calculating a Dividend.
                1</p>
            <p>.3 CALCULATION OF DIVIDEND:

                The Dividend shall be determined by dividing the Net Pool by the total number of unit bets on the winning combination/s. </p>
            <p>1.4.1 ABANDONED, CANCELLED AND RESCHEDULED MATCHES:

            </p>
            <p>If a match or matches, as advertised, is not completed by 9am on the day after the last day on which matches in the pool were scheduled, shall be deemed abandoned and all choices shall qualify.
                The times at which matches are completed will be as supplied by the operator's official source, notwithstanding the operator's decision in terms of when a match has not been completed shall be final and binding.

                The Operator reserves the right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed, provided this does not unduly hamper the declaration of results and dividends. </p>
            <p>1.4.2 ADVERTISED FIXTURES

                If, for any reason, the advertised fixture/s in the official programs are not in order as per Operator Rule 6.A above, the Operator shall declare, in accordance with the above-mentioned rule that all selections qualify as winning selections.
                1</p>
            <p>.4.3 REFUNDS:

                If all the matches, as designated in the official fixtures card, are abandoned, all bets shall be refunded. </p>
            <p>1.4.4 CARRY FORWARD:

                Should there be no winning Tickets on a Fantastic 4 pool, the Net Pool shall be carried forward and added to a net Footy Cash pool, selected at the sole discretion of Operator Management, provided that such carry forward is made within 12 months of the original pool or, in the case where it is the last Event of a particular season, it shall be carried forward and added to a net soccer pool within 12 months of the commencement of the new season.</p>
            <p> 1.5 POWER 6 POOL

            </p>
            <p>1.5.1 PURPOSE:</p>
            <p>The purpose of the POWER 6 is to select the result of six soccer matches as designated in the official soccer 6 fixtures card for each Event. There are three possible results for each match viz., 1 - Team A to win, 2 - a draw and 3 – Team B to win. </p>
            <p>1.5.2 UNIT OF BETTING AND MINIMUM BET: </p>
            <p>Minimum stake shall be pre-determined by operator in GHS </p>
            <p>1.5.3 RESULTS: </p>
            <p>1.5.3.1 The result of a match shall be the score at the end of the normal 90-minute playing period (40-minutes in the case of Futsal) plus any referee’s optional time that may be played. It shall not include extra time nor penalty shootouts. </p>
            <p>1.5.3.2 The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g. PSL Press Association and other designated official sources. 1.5.3.3 In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator was not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and for purposes of calculating a Dividend. </p>
            <p>1.5.4 CALCULATION OF DIVIDEND: </p>
            <p>The Dividend shall be determined by dividing the Net Pool by the total number of unit bets on the winning combination/s. </p>
            <p>1.5.5 ABANDONED, CANCELLED AND RESCHEDULED MATCHES:</p>
            <p> If a match or matches, as advertised, is not completed by 9am on the day after the last day on which matches in the pool were scheduled, shall be deemed abandoned and all choices shall qualify. </p>
            <p>The times at which matches are completed will be as supplied by the operator's official source, notwithstanding the operator's decision in terms of when a match has not been completed shall be final and binding. The Operator reserves the right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed, provided this does not unduly hamper the declaration of results and dividends. </p>
            <p>1.5.6 ADVERTISED FIXTURES </p>
            <p>If, for any reason, the advertised fixture/s in the official programs are not in order as per Operator Rule 6.A above, the Operator shall declare, in accordance with the above-mentioned rule that all selections qualify as winning selections. </p>
            <p>1.5.7 REFUNDS: If all the matches, as designated in the official fixtures card, are abandoned, all bets shall be refunded. </p>
            <p>1.5.8 CARRY FORWARD:</p>
            <p> Should there be no winning Tickets on a Power 6 pool, the Net Pool shall be carried forward and added to a net Footy Cash pool, selected at the sole discretion of Operator Management, provided that such carry forward is made within12 months of the original pool or, in the case where it is the last Event of a particular season, it shall be carried forward and added to a net soccer pool within 12 months of the commencement of the new season. </p>
            <p>1.8 MIGHTY 8 POOL </p>
            <p>1.8.1 PURPOSE: The purpose of the Soccer 8 is to select the result of eight soccer matches as designated in the official Soccer 8 fixtures card for each Event. There are three possible results for each match viz., 1 - Team A to win, 2 - a draw and 3 – Team B to win. </p>
            <p>1.8.2 UNIT OF BETTING AND MINIMUM BET: </p>
            <p>Minimum stake amount will be pre-determined by the operator in GHS </p>
            <p>1.8.3 RESULTS: </p>
            <p>1.8.3.1 The result of a match shall be the score at the end of the normal 90-minute playing period (40-minutes in the case of Futsal) plus any referee’s optional time that may be played. It shall not include extra time nor penalty shootouts. </p>
            <p>1.8.3.2 The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g., PSL Press Association and other designated official sources such as the GFA.</p>
            <p> 1.8.3.3 In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator was not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and for purposes of calculating a Dividend. </p>
            <p>1.8.4 CALCULATION OF DIVIDEND: </p>
            <p>The Dividend shall be determined by dividing the Net Pool by the total number of unit bets on the winning combination/s. </p>
            <p>1.8.5 ABANDONED, CANCELLED AND RESCHEDULED MATCHES: </p>
            <p>If a match or matches, as advertised, is not completed by 9am on the day after the last day on which matches in the pool were scheduled, shall be deemed abandoned and all choices shall qualify. </p>
            <p>The times at which matches are completed will be as supplied by the operator's official source, notwithstanding the operator's decision in terms of when a match has not been completed shall be final and binding.</p>
            <p> The Operator reserves the right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed, provided this does not unduly hamper the declaration of results and dividends. </p>
            <p>1.8.6 ADVERTISED FIXTURES </p>
            <p>If, for any reason, the advertised fixture/s in the official programs are not in order as per Operator Rule 6.A above, the Operator shall declare, in accordance with the above-mentioned rule that all selections qualify as winning selections. </p>
            <p>1.8.7 REFUNDS: If all the matches, as designated in the official fixtures card, are abandoned, all bets shall be refunded. </p>
            <p>1.8.8 CARRY FORWARD: </p>
            <p>Should there be no winning Tickets on a Mighty 8 pool, the Net Pool shall be carried forward and added to a net Footy Cash pool, selected at the sole discretion of Operator Management, provided that such carry forward is made within12 months of the original pool or, in the case where it is the last Event of a particular season, it shall be carried forward and added to a net soccer pool within 12 months of the commencement of the new season. </p>
            <p>1.9 FIRE 10 POOL 1.9.1 PURPOSE: </p>
            <p>The purpose of the Fire 10 is to select the result of ten soccer matches as designated in the official soccer 10 fixtures card for each Event. There are three possible results for each match viz., 1 - Team A to win, 2 - a draw and 3 – Team B to win. </p>
            <p>1.9.2 UNIT OF BETTING AND MINIMUM BET: </p>
            <p>Minimum stake amount will be pre-determined by the operator in GHS </p>
            <p>1.9.3 RESULTS: </p>
            <p>1.9.3.1 The result of a match shall be the score at the end of the normal 90-minute playing period plus any referee’s optional time that may be played. It shall not include extra time nor penalty shootouts </p>
            <p>1.9.3.2 The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g., PSL, Press Association and other designated official sources such as the GFA. </p>
            <p>1.9.3.3 In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator was not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and for purposes of calculating a Dividend. </p>
            <p>1.9.4 CALCULATION OF DIVIDEND: </p>
            <p>80% of the pool shall be allocated for bets with all 10 matches correct and the other 20% of the pool for entries with any nine of the 10 matches correct. If 80% portion of pool is not won, it shall be carried forward to the 80% portion of a selected Fire 10 pool. If no correct forecasts are made for the nine matches, 20% portion of the pool shall be carried forward to the 80% portion of a selected Fire 10 pool, at the discretion of the Operator. </p>
            <p>1.9.5 ABANDONED, CANCELLED AND RESCHEDULED MATCHES: </p>
            <p>If a match or matches, as advertised, is not completed by 9am on the day after the last day on which matches in the pool were scheduled, shall be deemed abandoned and all choices shall qualify. </p>
            <p>The times at which matches are completed will be as supplied by the operator's official source, notwithstanding the operator's decision in terms of when a match has not been completed shall be final and binding.</p>
            <p> The Operator reserves the right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed, provided this does not unduly hamper the declaration of results and dividends. </p>
            <p>1.9.6 REFUNDS: If all the matches, as designated in the official fixtures card, are abandoned, all bets shall be refunded. </p>
            <p>1.9.7 ADVERTISED FIXTURES </p>
            <p>If, for any reason, the advertised fixture/s in the official programs are not in order as per Operator Rule 6.A above, the Operator shall declare, in accordance with the above-mentioned rule that all selections qualify as winning selections. </p>
            <p>1.9.8 CARRY FORWARD:</p>
            <p> Should there be no winning Tickets on a Fire 10 pool, the Net Pool shall be carried forward and added to a net Footy Cash pool, selected at the sole discretion of Operator Management, provided that such carry forward is made within 12 months of the original pool. </p>
            <p>1.9.8.1 The Operator will declare a dividend based on official results of matches as provided by official sources of all matches, e.g., PSL, Press Association and other designated official sources such as the GFA. </p>
            <p>The Operator will not be liable for any errors or misprints as provided by the official sources. 1.9.8.1 In the event that a match started earlier than the advertised time, that is, before the close of the pool, and the Operator was not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and for purposes of calculating a dividend. </p>
            <p>2.0 SUPER JACKPOT 12 POOL </p>
            <p>1.9.1 PURPOSE: </p>
            <p>The purpose of the Jackpot 12 is to select the result of 12 soccer matches as designated in the official soccer 10 fixtures card for each Event. There are three possible results for each match viz., 1 - Team A to win, 2 - a draw and 3 – Team B to win. </p>
            <p>1.9.2 UNIT OF BETTING AND MINIMUM BET: </p>
            <p>Minimum stake amount will be pre-determined by the operator in GHS </p>
            <p>1.9.3 RESULTS: 1.9.3.1 </p>
            <p>The result of a match shall be the score at the end of the normal 90-minute playing period plus any referee’s optional time that may be played. It shall not include extra time nor penalty shootouts 1.9.3.2 The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g., PSL, Press Association and other designated official sources such as the GFA. 1.9.3.3 In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator was not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and for purposes of calculating a Dividend. </p>
            <p>1.9.4 CALCULATION OF DIVIDEND:</p>
            <p> 100% of the pool shall be allocated for bets with all 12 matches correct. Operator will set a minimum jackpot amount of GHS 5,000 to be divided amongst winning stakes at the end of the resulting period. </p>
            <p>1.9.5 ABANDONED, CANCELLED AND RESCHEDULED MATCHES:</p>
            <p> If a match or matches, as advertised, is not completed by 9am on the day after the last day on which matches in the pool were scheduled, shall be deemed abandoned and all choices shall qualify. </p>
            <p>The times at which matches are completed will be as supplied by the operator's official source, notwithstanding the operator's decision in terms of when a match has not been completed shall be final and binding. </p>
            <p>The Operator reserves the right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed, provided this does not unduly hamper the declaration of results and dividends. </p>
            <p>1.9.6 REFUNDS: If all the matches, as designated in the official fixtures card, are abandoned, all bets shall be refunded. </p>
            <p>1.9.7 ADVERTISED FIXTURES</p>
            <p> If, for any reason, the advertised fixture/s in the official programs are not in order as per Operator Rule 6.A above, the Operator shall declare, in accordance with the above-mentioned rule that all selections qualify as winning selections. </p>
            <p>1.9.8 CARRY FORWARD: </p>
            <p>Should there be no winning Tickets on a Fire 10 pool, the Net Pool shall be carried forward and added to a net Footy Cash pool, selected at the sole discretion of Operator Management, provided that such carry forward is made within 12 months of the original pool. </p>
            <p>1.9.8.1 The Operator will declare a dividend based on official results of matches as provided by official sources of all matches, e.g., PSL, Press Association and other designated official sources such as the GFA.</p>
            <p> The Operator will not be liable for any errors or misprints as provided by the official sources. 1.9.8.1 In the event that a match started earlier than the advertised time, that is, before the close of the pool, and the Operator was not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and for purposes of calculating a dividend. </p>
            <p>2.0 PAYOUT PAYMENTS </p>
            <p>Payments shall be made directly to the Mobile Money wallet that made the stake from. Payments will be initiated to MTN Mobile Money, AirtelTigo Money or Vodafone Cash.

            </p>



        </div>
    </>);
}

export default Tnc;