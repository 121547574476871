import { useEffect, useState } from "react";
import "./Slider.css";
import Carousel from "react-slick";
import axios from "axios";
import { pages_user } from "../../Api";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

export default function Slider() {

    const settings = {
        autoplay: true,
        autoplaySpeed: 4000,
        rows: 1,
        dots: false,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true
    };

    const [carouselPromos, setCarouselPromos] = useState([]);
    const [carouselPromosLinks, setCarouselPromosLinks] = useState([]);

    useEffect(() => {
        axios.get(pages_user("promotion", false))
            .then((response) => {
                if (response?.data?.status === 1) {
                    response?.data?.items?.map((item) => {
                        if (item.title === 'Landscape Promo') {
                            setCarouselPromos((currentPromos) => [...currentPromos, item])
                        }
                    })
                }
            })
            .catch(() => {
            });
        axios.get(pages_user("winner", false))
            .then((response) => {
                if (response?.data?.status === 1) {
                    response?.data?.items?.map((item) => {
                        // if (item.title === 'Landscape Promo') {
                            setCarouselPromosLinks((carouselPromosLinks) => [...carouselPromosLinks, item])
                        // }
                    })
                }
            })
            .catch(() => {
            })
    }, []);

    return (
        <>
            {carouselPromos && carouselPromos.length > 0 &&
                <div className="containerSlider">
                    <Carousel {...settings}>
                        {carouselPromos.map((promo, promoIndex) => {
                            return (
                                <Link to="/promo">
                                    <img key={promoIndex} className='promo_banner_landscape' src={promo.carouselImage} alt='Promo Landscape' />
                                </Link>
                            )
                        })}
                        {carouselPromosLinks.map((promo, promoIndex) => {
                            console.log("PROMO", promo);
                            return (
                                <a href={promo.content.replace('<p>', '').replace('</p>', '')} target="_blank">
                                    <img key={promoIndex} className='promo_banner_landscape' src={promo.carouselImage} alt='Promo Landscape' />
                                </a>
                            )
                        })}
                    </Carousel>
                </div>
            }
        </>
    );
}