let getPools = (status, startdate, enddate, matchnumbers) => `/moobifoot-web-api/getpools?status=${status}&fromEndDate=${startdate}&toEndDate=${enddate}&nbMatches=${matchnumbers}&accessChannel=Web`;
let getPoolDetailsApi = (poolid) => `/moobifoot-web-api/getpooldetails?poolId=${poolid}`;
let getPoolStatsApi = (poolid) => `/moobifoot-web-api/getpoolstats?poolId=${poolid}`;
let purchaseTicket = '/moobifoot-web-api/purchaseticket';
let getTicketsByUserToken = (isResulted) => `/moobifoot-web-api/getticketsbyusertoken?pageNumber=0&pageSize=100&isResulted=${isResulted}`;
let getTicketDetails = '/moobifoot-web-api/getticketdetails';
let latestresult = (teamid) => `/moobifoot-web-api/getlatestresults?teamId=${teamid}`
let previousresult = (teama, teamb) => `/moobifoot-web-api/getpreviousmatches?teamA=${teama}&teamB=${teamb}`
let userApi = (token) => `/user-api/tokens/${token}`;
let resetPasswordApi = (token) => `/user-api/tokens/${token}/passwords`;
let verifyMsisdnApi = (token) => `/user-api/tokens/${token}/msisdns`;
let registerApi = `/user-api/users`;
let forgotPasswordApi = (msisdn) => `/user-api/msisdns/${msisdn}/passwords`;
let resendVerificationCode = (token, msisdn) => `/user-api/tokens/${token}/msisdns/${msisdn}/codes`;
let contactUsApi = '/web-api/contact-us-messages/';
let wallet = (token) => `/wallet-transaction-api/tokens/${token}/wallets`;
let pages_user = (pagetype, IsFeatured) => `/web-api/pagetypes/${pagetype}?IsFeatured=${IsFeatured}&PageNumber=1&PageSize=100&Language=en`;
let addPlayerBet = `/moobifoot-web-api/addplayerbet`;
let getBetPoolId = `/moobifoot-web-api/getbetbypoolid`;
// let promoLandscapeApi = '/web-api/pages/41?language=en';
// let promoPortraitApi = '/web-api/pages/42?language=en';

export {
    getPools,
    getPoolDetailsApi,
    getPoolStatsApi,
    purchaseTicket,
    getTicketsByUserToken,
    getTicketDetails,
    latestresult,
    previousresult,
    userApi,
    resetPasswordApi,
    verifyMsisdnApi,
    registerApi,
    forgotPasswordApi,
    resendVerificationCode,
    contactUsApi,
    wallet,
    pages_user,
    addPlayerBet,
    getBetPoolId,
    // promoLandscapeApi,
    // promoPortraitApi
};
