import { useEffect, useState } from "react";
import { getTicketDetails } from "../../../Api";
import axios from 'axios';
import './BetDetails.css'
import BetMoreDetails from "../BetMoreDetails/BetMoreDetails";
import Loader from '../../Loader/Loader';
import { ErrorMessage } from '../../Loader/Loader';

export default function BetDetails(props) {

    const [loader, setLoader] = useState(null);
    const [ticket, setTicket] = useState(null);
    const [ticketDetails, setTicketDetails] = useState(null);
    const [moreDetailsVisibility, setMoreDetailsVisibility] = useState('');
    const [arrowStatus, setArrowStatus] = useState('betDetails__moreDetails-arrow');

    useEffect(() => {
        if (props.ticket) {
            setTicket(props.ticket);
            setMoreDetailsVisibility('');
            setArrowStatus('betDetails__moreDetails-arrow');
        }
    }, [props.ticket]);

    const getDetails = () => {
        setLoader(Loader);
        if (ticket) {
            const ticketDetailsObject = {
                ticketId: ticket.ticketId,
                userToken: localStorage.getItem("token")
            }
            let url = getTicketDetails;
            axios.post(url, ticketDetailsObject).then((response) => {
                setTicketDetails(response.data);
                setLoader(null);
            }).catch(() => {
                setLoader(ErrorMessage());
            })
        }
    }

    const expandMoreDetails = () => {
        getDetails();
        moreDetailsVisibility == '' ? setMoreDetailsVisibility('betDetails__moreDetails--visible') : setMoreDetailsVisibility('');
        arrowStatus == 'betDetails__moreDetails-arrow' ? setArrowStatus('betDetails__moreDetails-arrow--open') : setArrowStatus('betDetails__moreDetails-arrow');
    }

    return (
        <div className="betDetails-container">
            {ticket ?
                <>
                    <div className='betDetails__title'>{ticket.poolname}</div>
                    <div className='betDetails'>
                        <div className='betDetails__costSection'>
                            <div className='betDetails__costTitle'>Cost</div>
                            <div className='betDetails__costValue'>GHC {ticket.price}</div>
                        </div>
                        <div className='betDetails__ticketInfo'>
                            <span className='betDetails__ticketInfoTitle'>{ticket.poolname} </span>
                            <div className='betDetails__ticketInfoDetail'>
                                <span className='betDetails__ticketInfoDetailTitle'>Serial: </span>
                                <span className='betDetails__ticketInfoDetailValue'>{ticket.serialNumber}</span>
                            </div>
                            <div className='betDetails__ticketInfoDetail'>
                                <span className='betDetails__ticketInfoDetailTitle'>MSISDN: </span>
                                <span className='betDetails__ticketInfoDetailValue'>{localStorage.getItem('msisdn')}</span>
                            </div>
                        </div>
                        <div className='betDetails__winningsSection'>
                            <div className='betDetails__ticketStatus'>{ticket.ticketStatus}</div>
                            <div className='betDetails__winningsTitle'>Winnings</div>
                            <div className='betDetails__winningsValue'>GHC {ticket.prize}</div>
                        </div>
                    </div>
                    <div className="betDetails__moreDetails-title" onClick={() => expandMoreDetails()}>
                        More Details <span className={arrowStatus}></span>
                    </div>
                    <div className={'betDetails__moreDetails--hidden ' + moreDetailsVisibility}>
                        {loader}
                        <BetMoreDetails ticketDetails={ticketDetails} />
                    </div>
                </>
                :
                <></>
            }

        </div>
    );
}