import { useEffect, useState } from "react";
import LogoComponent from "../components/LogoComponent";
import axios from 'axios';
import Spinner from "../components/Spinner";
import ErrorDisplay from "../components/ErrorDisplay";
import { forgotPasswordApi } from "../Api";
import ReactGA from "react-ga4";

const Recovery = () => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/recovery" });
    }, [])

    const [payload, setPayload] = useState({
        mobileNumber: ""
    });

    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(false);

    const handleOnChange = (event) => {
        const newValue = {};
        newValue[event.target.name] = event.target.value;
        setPayload({ ...payload, ...newValue });
    }

    const handleOnSubmit = () => {
        const errors = Object.keys(payload)
            .map((input) => payload[input] === "" ? input : true)
            .filter((f) => f !== true);

        if (errors.length)
            setErr(`${errors[0]} is required`);
        else {
            setLoading(true);
            let url = forgotPasswordApi(payload.mobileNumber);

            setLoading(true);
            axios
                .post(url)
                .then((response) => {
                    setErr(response?.data?.message);
                    if (response?.data?.status === 1) {
                        localStorage.setItem("recovery", payload.mobileNumber);
                        window.location.href = '/reset';
                    }
                }).catch((err) => {
                    // console.log(err);
                    setErr(err.message);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }

    return (<>
        <div className="control-box">
            <div className="rads" id="login">
                <div className="login_auth"><h1>Account Recovery</h1><p>Recover your account</p></div>
                {err && <ErrorDisplay props={err} />}
                <div className="aut-box">

                    <LogoComponent />
                    <input type="tel" className="tel" placeholder="Mobile Number"
                        value={payload['mobileNumber']} name="mobileNumber"
                        onChange={(e) => handleOnChange(e)} />

                    {!loading ?
                        <button className="_c-signin" onClick={() => handleOnSubmit()}>Submit</button> :
                        <div style={{ textAlign: 'center' }}><Spinner fill="#fff" bg="rgba(0,0,0,0)" /></div>}
                    <div className="small-text"><p><a href="/login">Back to Login</a></p></div>
                </div>


            </div></div>
        <footer>&copy; 2022 Footycash. All rights reserved.</footer>
    </>);
}

export default Recovery;