import { useEffect, useState } from "react";
import axios from 'axios';
import Spinner from "../components/Spinner";
import ErrorDisplay from "../components/ErrorDisplay";
import { forgotPasswordApi } from "../Api";
import ReactGA from "react-ga4";

const Recovery = () => {
    const [payload, setPayload] = useState({
        mobileNumber: "",
    });

    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/reset" });
    }, [])

    useEffect(() => {
        const setDefault = payload;
        setDefault.mobileNumber = localStorage.getItem("recovery") || '';
        setDefault.password = "";
        setDefault.confirmPassword = "";
        setDefault.code = "";
        setPayload(setDefault);
    }, []);

    const handleOnChange = (event) => {
        const newValue = {};
        newValue[event.target.name] = event.target.value;
        setPayload({ ...payload, ...newValue });
    }

    const handleOnSubmit = () => {
        const errors = Object.keys(payload)
            .map((input) => payload[input] === "" ? input : true)
            .filter((f) => f !== true);

        if (errors.length)
            setErr(`${errors[0]} is required`);
        else if (payload.password !== payload.confirmPassword) {
            setErr(`Passwords do not match`);
        } else {
            setLoading(true);
            let url = forgotPasswordApi(payload.mobileNumber);
            axios
                .put(url, payload)
                .then((response) => {
                    setErr(response?.data?.message);
                    setTimeout(() => {
                        window.location.href = '/login'
                    }, 200);
                }).catch((err) => {
                    // console.log(err);
                    setErr(err.message);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }

    return (<>
        <div className="control-box">
            <div className="rads" id="login">
                <div className="login_auth"><h1>Reset Password</h1><p>Recover your account</p></div>
                {err && <ErrorDisplay props={err} />}
                <div className="aut-box">

                    <input type="password" className="tel" placeholder="Code"
                        value={payload['code']} name="code"
                        onChange={(e) => handleOnChange(e)} />

                    <input type="password" className="tel" placeholder="Password"
                        value={payload['password']} name="password"
                        onChange={(e) => handleOnChange(e)} />

                    <input type="password" className="tel" placeholder="Confirm Password"
                        value={payload['confirmPassword']} name="confirmPassword"
                        onChange={(e) => handleOnChange(e)} />

                    {!loading ?
                        <button className="_c-signin" onClick={() => handleOnSubmit()}>Submit</button> :
                        <div style={{ textAlign: 'center' }}><Spinner fill="#fff" bg="rgba(0,0,0,0)" /></div>}
                    <div className="small-text"><p><a href="/login">Back to Login</a></p></div>
                </div>


            </div></div>
        <footer>&copy; 2022 Footycash. All rights reserved.</footer>
    </>);
}

export default Recovery;