import { useState, useEffect } from 'react';
import axios from 'axios';
import './Results.css';
import '../../components/poolsdetails/Poolsdetails.css'
import { getPools } from "../../Api";
import Poolsdetails from '../../components/poolsdetails/Poolsdetails';
import { Dropdown } from 'react-dropdown-now';
import 'react-dropdown-now/style.css';
import Loader, { ErrorMessage } from '../../components/Loader/Loader';
import ReactGA from "react-ga4";

export default function Results() {

    let matches_numb = [];

    const [loaderDate, setLoaderDate] = useState(null);
    const [responsee, setresponsee] = useState([]);
    const [btnactivate, setbtnactivate] = useState(null);
    const [filternumb, setfilter] = useState(0);
    const [pools, setpools] = useState([]);
    const [month, setmonth] = useState('');
    const [year, setyear] = useState('');
    const [extendInfo, setextendInfo] = useState(null);
    const [resultsArraySize, setResultsArraySize] = useState(0);
    const [monthDisabled, setMonthDisabled] = useState('');
    const [poolYears, setPoolYears] = useState([]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/results" });
    }, [])

    const moment = require('moment');

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        let years = [];
        let url = getPools(0, '', '', '');
        axios.get(url)
            .then((response) => {
                setresponsee(response.data.pools);
                response.data.pools.reduce((groups, game) => {
                    years.push(moment(game.pool_enddate).format('YYYY'));
                });
                setPoolYears(years);
            })
            .catch(() => {
                ErrorMessage();
            })
    }

    useEffect(() => {
        extend(-1, -1);
        let url;
        let day = '-01';
        let endday = '-31';
        let startdate = year.concat(-1).concat(day)
        let enddate = year.concat(-12).concat(endday)
        if (year !== '') {
            if (month) {
                startdate = year.concat(-month).concat(day)
                enddate = year.concat(-month).concat(endday)
            }

            url = getPools(0, startdate, enddate, '')
            if (filternumb > 0) {
                url = getPools(0, startdate, enddate, filternumb)
            }
        }
        else if (filternumb > 0) {
            url = getPools(0, '', '', filternumb)
            if (month && year !== '') {
                url = getPools(0, startdate, enddate, filternumb)
            }
        }
        else {
            url = getPools(0, '', '', '');
        }
        setLoaderDate(Loader);
        axios.get(url)
            .then((response) => {
                setpools(response.data.pools);
                setLoaderDate(null);
            })
            .catch(() => {
                setLoaderDate(ErrorMessage());
            })

    }, [filternumb, month, year])


    const groups = pools.reduce((groups, game) => {
        const date = moment(game.pool_enddate).format('dddd, D MMMM YYYY');
        poolYears.push(moment(game.pool_enddate).format('YYYY'));
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(game);
        return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => {
        return {
            date: date,
            pools: groups[date]
        };
    });

    useEffect(() => {
        if (pools) {
            if (pools.length > resultsArraySize) {
                setResultsArraySize(pools.length)
            }
        }
    }, [pools]);

    useEffect(() => {
        if (resultsArraySize > 0) {
            setextendInfo(Array(resultsArraySize).fill('').map(row => new Array(resultsArraySize).fill('')));
        }
    }, [resultsArraySize]);

    const handlefilterbtn = (item, index) => {
        if (btnactivate === index && filternumb === item) {
            setbtnactivate(null);
            setfilter(0)
        }
        else {
            setbtnactivate(index);
            setfilter(item);
        }
    }


    for (var i = 0; i < responsee.length; i++) {
        matches_numb.push(responsee[i].pool_nb_matches)
    }
    let filterable_matches = new Set(matches_numb);
    matches_numb = [...filterable_matches].sort();

    const monthtoint = (months) => {
        switch (months) {
            case 'january':
                setmonth('01')
                break;
            case 'february':
                setmonth('02')
                break;
            case 'march':
                setmonth('03')
                break;
            case 'april':
                setmonth('04')
                break;
            case 'may':
                setmonth('05')
                break;
            case 'june':
                setmonth('06')
                break;
            case 'july':
                setmonth('07')
                break;
            case 'august':
                setmonth('08')
                break;
            case 'september':
                setmonth('09')
                break;
            case 'october':
                setmonth('10')
                break;
            case 'november':
                setmonth('11')
                break;
            case 'december':
                setmonth('12')
                break;

            default:
        }

    }

    const extend = (valueIndex, tagIndex) => {
        if (extendInfo) {
            let tempArray = [...extendInfo];
            tempArray.map((tempX, tempXindex) => {
                tempX.map((tempY, tempYindex) => {
                    if (tempXindex == valueIndex && tempYindex == tagIndex) {
                        tempArray[tempXindex][tempYindex] === "showinfo" ? tempArray[tempXindex][tempYindex] = "" : tempArray[tempXindex][tempYindex] = "showinfo";
                    }
                    else {
                        tempArray[tempXindex][tempYindex] = "";
                    }
                })
            })
            setextendInfo(tempArray);
        };
    }

    useEffect(() => {
        year == '' ? setMonthDisabled('monthDisabled') : setMonthDisabled('');
    }, [year]);

    return (

        <div className="tabs-content">
            <div className="game-container__wrapper">
                <div className="pastpools-container">
                    <div className="pastpool-container-datefilter">
                        <div className="datefilter-wrapper">
                            <Dropdown
                                options={['All', ...new Set(poolYears)]}
                                placeholder={"year"}
                                onChange={(value) => value.value == 'All' ? setyear('') : setyear(value.value)}

                            />
                        </div>
                        <div className={"datefilter-wrapper " + monthDisabled}>
                            <Dropdown
                                options={['All', 'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']}
                                placeholder={"month"}
                                disabled={year == '' ? true : false}
                                onChange={(value) => value.value == 'All' ? setmonth('') : monthtoint(value.value)}
                            />
                        </div>
                    </div>
                    <div className="pastpools-container-filter">
                        <div className="pastpools-container-filter-textblock">
                            <span className="pastpools-container-filter-textblock__title">Filter by Pool Size</span>
                        </div>
                        <div className="filter-pools">
                            {matches_numb ? matches_numb.map((item, index) => {
                                return (
                                    <div key={index} className={index === btnactivate ? "pastpools-wrapper activate__pastpool__matches" : "pastpools-wrapper"} onClick={() => handlefilterbtn(item, index)}>
                                        <span className={index === btnactivate ? "wrapper__item activated" : "wrapper__item"}>{item}</span>
                                    </div>
                                )
                            })
                                : ''}

                        </div>
                    </div>
                    {extendInfo ?
                        groupArrays ? groupArrays.map((value, valueIndex) => (
                            <div key={valueIndex}>
                                {value.pools.map((tag, tagIndex) => {
                                    return (
                                        <>
                                            <div key={valueIndex + ',' + tagIndex} className={'detailspool-container ' + extendInfo[valueIndex][tagIndex]} onClick={() => extend(valueIndex, tagIndex)} >
                                                <Poolsdetails poolId={tag.pool_id} date={value.date} extendinfo={extendInfo[valueIndex][tagIndex]} />
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        )) : ''
                        :
                        <></>}
                    {loaderDate}

                </div>
            </div>
        </div>
    )
}

