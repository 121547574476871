import moment from 'moment';
import './Pool.css';
import { Link } from 'react-router-dom';
import { getPoolStatsApi } from '../../Api';
import { useEffect, useState } from 'react';
import axios from 'axios';

export default function Pool(props) {

	const [totalPayout, setTotalPayout] = useState(null);
	const [userPayout, setUserPayout] = useState(null);
	const [winnersCount, setWinnersCount] = useState(null);

	const getPoolStats = (poolId) => {
		if (poolId) {
			let url = getPoolStatsApi(poolId);
			axios.get(url)
				.then((response) => {
					if (response?.data?.status === 0) {
						setTotalPayout(response?.data?.totalPayout);
						setUserPayout(response?.data?.userPayout);
						setWinnersCount(response?.data?.numberOfWinners);
					}
				})
				.catch((err) => {
					console.log(err.message);
				})
		}
	}

	useEffect(() => {
		if (props.poolitem.pool_id && props.inactivePools) {
			getPoolStats(props.poolitem.pool_id);
		}
	}, []);

	return (
		<div className="pool-container">
			<div className="pool-container__title">
				<div className="pool-container__titleCount">
					<span className="pool-container__title-pooltitle">{props.poolitem.pool_title}</span>
					<div className="pool-container__title-matchcount">
						<span className="pool-container__title-matchcount__count">{props.poolitem.pool_nb_matches}</span>
					</div>
				</div>
				{props.inactivePools ?
					<span className="pool-container__title-pooltitle">closed</span>
					:
					<></>
				}
			</div>
			<Link className="pool-container__link" to={`/pools/${props.poolitem.pool_id}`} state={props.inactivePools} >
				<div className="pool-container__block">
					<div className="pool-container__block-desc">
						<span className="pool-container__block-desc__mess">{props.poolitem.pool_desc ? props.poolitem.pool_desc : 'description'}</span>
						{props.inactivePools ?
							<span className="pool-container__block-desc__date"> closed</span>
							:
							<span className="pool-container__block-desc__date"> closing {moment(props.poolitem.pool_enddate).format('YYYY-MM-DD hh:mm')}</span>
						}
					</div>
					<div className="pool-container__block-stake">
						<div className="pool-container__block-price">ghc {props.poolitem.pool_ticket_price}</div>
						<span className="pool-container__block-stake__amount">Stake amount</span>
					</div>
				</div>
			</Link>

			<div className="pool-container__pool-info">
				{totalPayout || userPayout || winnersCount ?
					<div className="pool-container__pool-stats">
						{totalPayout ? <span>Total Payout: {totalPayout}</span> : ''}
						{winnersCount ? <span>Winners Nb: {winnersCount}</span> : ''}
						{userPayout ? <span>Total User Payout: {userPayout}</span> : ''}
					</div>
					:
					<></>
				}

				{props.poolitem.maxWin ?
					<div className="pool-container__total-pool-amount">Total Pool Amount: GHC {props.poolitem.maxWin}</div>
					:
					<></>
				}
			</div>
		</div>
	)
}
