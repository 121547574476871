import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import axios from "axios";
import { pages_user } from "../Api";
import Loader, { ErrorMessage } from '../components/Loader/Loader';

const Promo = () => {

    const [promoContent, setPromoContent] = useState(null);
    const [promoImage, setPromoImage] = useState(null);
    const [loader, setLoader] = useState(null);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/promo" });
    }, []);

    useEffect(() => {
        axios.get(pages_user("promotion", true))
            .then((response) => {
                setLoader(Loader);
                if (response?.data?.status === 1) {
                    setPromoContent(response?.data?.items[0]?.content);
                    setPromoImage(response?.data?.items[0]?.carouselImage);
                    setLoader(null);
                } else {
                    setLoader(ErrorMessage(response.data.message));
                }
            })
            .catch((err) => {
                setLoader(ErrorMessage(err));
            });
    }, []);

    return (
        <>
            {promoImage ?
                <div className="promo_banner">
                    <img src={promoImage} />
                </div>
                :
                <></>
            }

            {promoContent ?
                <div className="l-page-content">
                    <div dangerouslySetInnerHTML={{ __html: promoContent }} />
                </div>
                :
                <></>
            }

            {loader}

        </>);
}

export default Promo;