import FootComponent from "../components/FootComponent";
import HeaderComponent from "../components/HeaderComponent";
import { Outlet } from "react-router-dom"

const OpenLayout = () => {

    return (
        <>
            <HeaderComponent />
            <section id="site_body" className="l_page">
                <div className="grid grid-pad">
                    <div className="col-1-1">
                        <Outlet />
                    </div>
                </div>
            </section>
            <FootComponent />
        </>
    );
}

export default OpenLayout;