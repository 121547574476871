export default function Popup(props) {

    return (
        <div className={"popup-container popupvisible"}>
            <div className="popup-container-titlewrapperpopup">
                <span className="titlewrapperpopup__title">{props.title}</span>
            </div>
            <div className="popup-container-data">
                <p className="popup-container-data__betdata">
                    {props.data}
                </p>
            </div>
            <div className="popup-container-actions">
                <button
                    className="popup-container-actions__btnaction cancelbetbtn"
                    onClick={() => {
                        props.showPopup(false);
                    }}
                >
                    {props.options[0]}
                </button>
            </div>
        </div>
    );
}