import { useState, useEffect } from "react";
import LogoComponent from "../components/LogoComponent";
import Spinner from "../components/Spinner";
import ErrorDisplay from "../components/ErrorDisplay";
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

const Register = (props) => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/register" });
    }, [])

    const [payload, setPayload] = useState({
        firstName: "",
        lastName: "",
        mobileNumber: "",
        password: "",
        confirmPassword: "",
        operator: ""
    });
    const [showpass, setShowPass] = useState(false);
    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(false);

    const handleOnChange = (event) => {
        const newValue = {};
        newValue[event.target.name] = event.target.value;
        setPayload({ ...payload, ...newValue });
    }

    const handleOnSubmit = () => {
        const errors = Object.keys(payload)
            .map((input) => payload[input] === "" ? input : true)
            .filter((f) => f !== true);

        if (errors.length)
            setErr(`${errors[0]} is required`);
        else if (payload.password !== payload.confirmPassword)
            setErr(`Passwords do not match`);
        else {
            setLoading(true);
            props.onRegister(payload.firstName,
                payload.lastName,
                payload.mobileNumber,
                payload.password,
                payload.confirmPassword,
                payload.operator);
            localStorage.setItem("payload", JSON.stringify(payload));
            setErr(props.regerror);
            setLoading(false);
        }
    }

    return (<>
        <div className="cards_brand"><Link to="/" className="rans_logo"></Link></div>
        <div className="control-box">
            <div className="rads signup" id="login">
                <div className="login_auth"><h1>Register</h1><p>Create a Footy Cash account</p></div>
                <div className="aut-box">
                    {err && <ErrorDisplay props={err} />}
                    <input type="text" value={payload['firstName']} placeholder="First Name" name="firstName" onChange={(e) => handleOnChange(e)} />
                    <input type="text" value={payload['lastName']} placeholder="Surname" name="lastName" onChange={(e) => handleOnChange(e)} />

                    <div className="c_flag_misdin">
                        <LogoComponent />
                        <input type="tel" value={payload['mobileNumber']} className="tel" name="mobileNumber" placeholder="Mobile Number" onChange={(e) => handleOnChange(e)} />
                    </div>

                    <select name="operator" value={payload['operator']}
                        onChange={(e) => handleOnChange(e)}>
                        <option value="">Network</option>
                        <option value="MTN">MTN</option>
                        <option value="VODAFONE">Vodafone</option>
                        <option value="AIRTELTIGO">Airtel Tigo</option>
                    </select>

                    <input type="password" value={payload['password']} name="password" placeholder="Password" onChange={(e) => handleOnChange(e)} />
                    <div className="c_flag_misdin">
                        <input type={!showpass ? 'password' : 'text'} placeholder="Confirm Password" id="password" value={payload['confirmPassword']} className="masked" name="confirmPassword" onChange={(e) => handleOnChange(e)} />
                        <span className="eye-icon" onClick={() => setShowPass(!showpass)}><i className={`fa ${showpass ? 'fa-eye-slash' : 'fa-eye'}`} id="eye"></i></span>
                    </div>
                    {!loading ?
                        <button className="_c-signin" onClick={() => handleOnSubmit()}>register</button> :
                        <div style={{ textAlign: 'center' }}><Spinner fill="#fff" bg="rgba(0,0,0,0)" /></div>}

                    <div className="small-text">
                        <p>
                            <a href="/login">already have an account?</a>
                        </p>
                    </div>
                </div>


            </div>
        </div>
        <footer>&copy; 2022 Footycash. All rights reserved.</footer>
    </>);
}

const mapStateToProps = (state) =>
({
    regerror: state.auth.regerror,
});

const mapDispatchToProps = (dispatch) =>
({
    onRegister: (
        firstName,
        lastName,
        mobileNumber,
        password,
        confirmPassword,
        operator) => dispatch(actions.register(
            firstName,
            lastName,
            mobileNumber,
            password,
            confirmPassword,
            operator))
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
