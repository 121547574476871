import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import authReducer from "../store/reducers/auth";

const rootReducer = combineReducers({
    auth: authReducer,
});

const storeMoobitek = configureStore({
    reducer: rootReducer,
    middleware: [thunk],
});

export default storeMoobitek;