import { useEffect } from "react";
import ReactGA from "react-ga4";

const HowToPlay = () => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/howtoplay" });
    }, [])

    return (
        <>
            <h3>How To Play</h3>
            <div className="l-page-content">
                <p>Follow these simple steps to enjoy the convenience of playing Footycash online:</p>
                <br />
                <p>1. Visit footycash website on any connected device to access the Footycash portal.</p>
                <p>2. For first timers on the web, click on “REGISTER” button and signup to be able to access the pools anytime.</p>
                <p>3. On the home page, the various football polls of the day will be displayed.</p>
                <p>4. Simply select your preferred mobile network to play and enter your registered mobile money number.</p>
                <p>5. Click to choose your preferred pool  (GPL 4, Fantastic 4 or EPL 4) and follow prompts to play and enter the required amount to stake.</p>
                <p>6. You will receive an SMS prompt on your registered mobile money phone to confirm your PIN and approve your stake amount.</p>
                <p>7. You will receive an SMS ticket confirming your stake and game details.</p>
                <p>8. You will also receive an SMS at the end of all the matches in the pool.</p>
                <p>9. All winnings will be paid directly into your mobile money account after pool confirmations (if you predict correctly the outcome of all the games in the pool).</p>

            </div>
        </>);
}

export default HowToPlay;
