export const UPDATE_ACTIVE_BUTTONS = 'UPDATE_ACTIVE_BUTTONS';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const VER_START = 'VER_START';
export const VER_FAIL = 'VER_FAIL';
export const AUTH_REG_FAIL = 'AUTH_REG_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const REG_START = 'REG_START';
export const REDIRECT_VERIFY = 'REDIRECT_VERIFY';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const UPDATE_WALLET = 'UPDATE_WALLET';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';