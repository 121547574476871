import axios from "axios";
import { useEffect, useState } from "react";
import { contactUsApi } from "../Api";
import ErrorDisplay from "../components/ErrorDisplay";
import Spinner from "../components/Spinner";
import ReactGA from "react-ga4";

const Contact = () => {
    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState({
        fullName: '',
        email: '',
        mobileNumber: '',
        message: ''
    });

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/contacts" });
    }, [])

    const handleOnChange = (event) => {
        const newValue = {};
        newValue[event.target.name] = event.target.value;
        setPayload({ ...payload, ...newValue });
    }

    const handleOnSubmit = () => {

        const errors = Object.keys(payload)
            .map((input) => payload[input] === "" ? input : true)
            .filter((f) => f !== true);

        if (errors.length)
            setErr(`${errors[0]} is required`);
        else if (payload.mobileNumber.length != 10) {
            setErr(`Mobile number is invalid eg. 0202000000`);
        } else if (!payload.email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
            setErr(`Email address is invalid`);
        }
        else {

            setLoading(true);
            let url = contactUsApi;
            axios
                .post(url, payload)
                .then((response) => {
                    setErr(response?.data?.message);
                    setPayload({
                        fullName: '',
                        email: '',
                        mobileNumber: '',
                        message: ''
                    });
                }).catch((err) => {
                    setErr(err.message);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }

    return (
        <>
            <h3>Contact Customer Care</h3>
            {err && <ErrorDisplay props={err} />}
            <div className="l-page-content">
                <div className="grp-lft">
                    <label>Full Name</label>
                    <input type="text" placeholder="Full Name"
                        name="fullName" onChange={(e) => handleOnChange(e)}
                        value={payload.fullName} />

                    <p><label>Email Address</label>
                        <input type="text" placeholder="Email Address"
                            name="email" onChange={(e) => handleOnChange(e)}
                            value={payload.email}
                        /></p>

                    <p><label>Mobile Number</label>
                        <input type="tel" placeholder="Mobile Number"
                            name="mobileNumber" onChange={(e) => handleOnChange(e)}
                            value={payload.mobileNumber} /></p>
                </div>


                <div className="grp-rt">

                    <label>Message</label>
                    <textarea rows={9} name="message" onChange={(e) => handleOnChange(e)} value={payload.message}></textarea>
                </div>
                {!loading ?
                    <button onClick={() => handleOnSubmit()}>Send Message</button> : <div style={{ textAlign: 'center' }}><Spinner fill="#fff" bg="rgba(0,0,0,0)" /></div>}
            </div>

        </>);
}

export default Contact;