import { useEffect } from "react";
import ReactGA from "react-ga4";

const GameTnc = () => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/gameterms" });
    }, [])

    return (
        <>
            <h3>Game Terms</h3>
            <div className="l-page-content">
                <p>D – Draw</p>
                <p>HW - Home Win</p>
                <p>AW - Away Win</p>
                <p>HW 2 - Home Club wins by 2 or more goals</p>
                <p>HW 1 - Home team wins by 1 goal</p>
                <p>SCD - Score Draw</p>
                <p>AW1 - Away Win by 1</p>
                <p>AW2 - Away Win bye 2 or more goals</p>
                <p>NOSC - No Score (0-0)</p>
            </div>

        </>);
}

export default GameTnc;