import axios from "axios";
import { useEffect, useState } from "react";
import { resetPasswordApi, userApi } from "../Api";
import ErrorDisplay from "../components/ErrorDisplay";
import Spinner from "../components/Spinner";
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';
import ReactGA from "react-ga4";

const Home = (props) => {
    const [activeTab, setActiveTab] = useState(1);
    const [payload, setPayload] = useState({});
    const [resetPayload, setResetPayload] = useState({});
    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/profile" });
    }, [])

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("setUserData") || '{}');
        setPayload({
            mobileNumber: userData.mobileNumber.value,
            firstName: userData.firstName,
            lastName: userData.lastName,
            operator: userData?.mobileNumber?.operator
        });
        setResetPayload({
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        });

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('data') === "security") {
            setActiveTab(2);
        }
    }, []);

    const handleOnChange = (event) => {
        const newValue = {};
        newValue[event.target.name] = event.target.value;
        setPayload({ ...payload, ...newValue });
    }

    const handleResetOnChange = (event) => {
        const newValue = {};
        newValue[event.target.name] = event.target.value;
        setResetPayload({ ...resetPayload, ...newValue });
    }


    const handleResetSubmit = (event) => {
        event.preventDefault();
        const errors = Object.keys(resetPayload)
            .map((input) => resetPayload[input] === "" ? input : true)
            .filter((f) => f !== true);

        if (errors.length)
            setErr(`${errors[0]} is required`);
        else if (resetPayload.newPassword !== resetPayload.confirmPassword) {
            setErr(`Passwords do not match`);
        } else {
            const authData = {
                oldPassword: payload.oldPassword,
                newPassword: payload.newPassword,
                confirmPassword: payload.confirmPassword,
            };

            let url = resetPasswordApi(localStorage.getItem("token"));

            axios
                .patch(url, authData)
                .then((response) => {

                    setLoading(true);
                    if (response.data.status === 1) {
                        setErr(response?.data?.message);
                        setLoading(false);
                    } else {
                        setErr(response?.data?.message);
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    setErr(err.message);
                    setLoading(false);
                });
        }
    }

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const errors = Object.keys(payload)
            .map((input) => payload[input] === "" ? input : true)
            .filter((f) => f !== true);

        if (errors.length)
            setErr(`${errors[0]} is required`);
        else {
            setLoading(true);
            let url = userApi(localStorage.getItem('token'));
            axios.patch(url, payload)
                .then((response) => {
                    setErr(response?.data?.message);
                    if (response?.data?.status === 1) {
                        localStorage.setItem("setUserData", JSON.stringify(response?.data?.user));
                    }
                }).catch((err) => {
                    setErr(err.message);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }

    return (<section className="tabs-content">
        {activeTab === 1 ?
            <div id="gfa_in_tabs-1">
                <article className="grp_inputs card">
                    {err && <ErrorDisplay props={err} />}
                    <article className="account_basic_information">

                        <article className="left_basic">
                            <aside>Telephone Number</aside>
                            <aside>First Name</aside>
                            <aside>Last Name</aside>
                        </article>


                        <article className="right_basic">

                            <article className="_mobile_header">Basic Information</article>
                            <aside className="item">
                                <h5>Telephone Number</h5>
                                <input type="number" name="" className="disable-box" placeholder="245***895" disabled
                                    value={`0${payload?.mobileNumber?.substring(3)}`} />
                            </aside>
                            <aside className="item">
                                <h5>First Name</h5>
                                <input type="text" placeholder="First Name"
                                    value={payload.firstName} name="firstName" onChange={(e) => handleOnChange(e)} />
                            </aside>
                            <aside className="item">
                                <h5>Last Name</h5>
                                <input type="text" placeholder="Last Name"
                                    value={payload.lastName} name="lastName" onChange={(e) => handleOnChange(e)} />
                            </aside>
                        </article>
                    </article>
                </article>
                <article className="misdn">
                    <span className="network_typ">Select Mobile Network</span>
                    <label className="check-reset"><img src="img/pro/mnos-mtn.png" />
                        <input type="radio" name="operator" value="mtn-gh"
                            onClick={() => {
                                const newValue = {};
                                newValue.operator = 'MTN';
                                setPayload({ ...payload, ...newValue });
                            }} checked={payload.operator === "MTN"} />
                        <span className="checkmark"></span> </label>
                    <label className="check-reset"><img src="img/pro/mnos-airtel.png" />
                        <input type="radio" name="operator" value="tigo-gh"
                            onClick={() => {
                                const newValue = {};
                                newValue.operator = 'AIRTELTIGO';
                                setPayload({ ...payload, ...newValue });
                            }} checked={payload.operator === "AIRTELTIGO"} />
                        <span className="checkmark"></span> </label>
                    <label className="check-reset"><img src="img/pro/mnos-voda.png" />
                        <input type="radio" name="operator" value="vodafone-gh-ussd"
                            onClick={() => {
                                const newValue = {};
                                newValue.operator = 'VODAFONE';
                                setPayload({ ...payload, ...newValue });
                            }} checked={payload.operator === "VODAFONE"} />
                        <span className="checkmark"></span> </label>
                </article>
                <article className="save_btn">
                    {!loading ?
                        <button className="_c-signin">
                            <a href="#" onClick={(e) => handleOnSubmit(e)}>Save</a>
                        </button> :
                        <div style={{ textAlign: 'center' }}><Spinner fill="#fff" bg="rgba(0,0,0,0)" /></div>}
                </article>
            </div>
            :
            <div id="gfa_in_tabs-1">
                {err && <ErrorDisplay props={err} />}

                <article className="grp_inputs card">

                    <article className="account_basic_information">
                        <article className="left_basic">
                            <aside>Old Password</aside>
                            <aside>Enter New Password</aside>
                            <aside>Confirm Password</aside>
                        </article>
                        <article className="right_basic">
                            <article className="_mobile_header">Account Security</article>
                            <aside className="item"><h5>Old Password</h5><input type="password" className="" placeholder="Old Password"
                                value={resetPayload.firstName} name="oldPassword" onChange={(e) => handleResetOnChange(e)} /></aside>
                            <aside className="item"><h5>Enter New Password</h5><input type="text" placeholder="Enter New Password"
                                value={resetPayload.firstName} name="newPassword" onChange={(e) => handleResetOnChange(e)} /></aside>
                            <aside className="item"><h5>Confirm Password</h5><input type="password" placeholder="Confirm Password"
                                value={resetPayload.firstName} name="confirmPassword" onChange={(e) => handleResetOnChange(e)} /></aside>
                        </article>
                    </article>
                </article>
                <article className="save_btn">
                    {!loading ?
                        <button className="_c-signin">
                            <a href="#" onClick={(e) => handleResetSubmit(e)}>Reset</a>
                        </button> : <div style={{ textAlign: 'center' }}><Spinner fill="#fff" bg="rgba(0,0,0,0)" /></div>}
                </article>
            </div>
        }
    </section>);
}

const mapStateToProps = (state) =>
({
    isAuthenticated: state.auth.token !== null
});

const mapDispatchToProps = (dispatch) =>
({
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onAuth: (username, password) => dispatch(actions.auth(username, password)),
    logout: () => dispatch(actions.logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);