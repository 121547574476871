import { Outlet } from "react-router-dom"

const OpenLayout = () => {

    return (
        <>
            <div className="content s_main_body_static">
                <Outlet />
            </div>
        </>
    );
}

export default OpenLayout;