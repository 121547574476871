import { useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import axios from 'axios';
import ErrorDisplay from "../components/ErrorDisplay";
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';
import { resendVerificationCode } from "../Api";
import ReactGA from "react-ga4";

const Register = (props) => {
    const [payload, setPayload] = useState({
        mobileNumber: "",
        code: "",
    });

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/verification" });
    }, [])

    useEffect(() => {
        const tokenValue = {};
        const payloadCache = JSON.parse(localStorage.getItem("payload") || '{}');
        tokenValue.token = localStorage.getItem("token");
        tokenValue.mobileNumber = payloadCache.mobileNumber;
        tokenValue.type = 1;
        setPayload({ ...payload, ...tokenValue });
    }, []);

    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(false);

    const handleOnChange = (event) => {
        const newValue = {};
        newValue[event.target.name] = event.target.value;
        setPayload({ ...payload, ...newValue });
    }

    const handleOnSubmit = () => {
        const errors = Object.keys(payload)
            .map((input) => payload[input] === "" ? input : true)
            .filter((f) => f !== true);

        if (errors.length)
            setErr(`${errors[0]} is required`);
        else {
            setLoading(true);

            props.onVerify(payload.code);
            setErr(props.vererror ? props.vererror : props.verify);
            setLoading(false);
        }
    }

    const handleResendCode = () => {
        setLoading(true);
        let msisdn = localStorage.getItem('unverified_msisdn');
        let token = localStorage.getItem('unverified_token');
        axios.put(resendVerificationCode(token, msisdn))
            .then((response) => {
                setErr(response?.data?.message);
            }).catch((err) => {
                setErr(err.message);
            }).finally(() => {
                setLoading(false);
            });
    }


    return (<>
        <div className="control-box">
            <div className="rads signup" id="login">
                <div className="login_auth"><h1>Account Verification</h1><p>Enter the verication code to activate your account</p></div>
                <div className="aut-box">
                    {err && <ErrorDisplay props={err} />}
                    <input type="password" value={payload['code']} name="code" placeholder="Code" onChange={(e) => handleOnChange(e)} />

                    {!loading ?
                        <button className="_c-signin" onClick={() => handleOnSubmit()}>submit</button> :
                        <div style={{ textAlign: 'center' }}><Spinner fill="#fff" bg="rgba(0,0,0,0)" /></div>}


                    <div className="small-text">
                        <p>
                            <a style={{ cursor: 'pointer' }} onClick={() => handleResendCode()}>Resend verification code</a>
                        </p>
                    </div>

                    <div className="small-text">
                        <p>
                            <a href="/login">already have an account?</a>
                        </p>
                    </div>
                </div>


            </div>
        </div>
        <footer>&copy; 2022 Footycash. All rights reserved.</footer>
    </>);
}

const mapStateToProps = (state) =>
({
    verify: state.auth.verify,
    isAuthenticated: state.auth.token !== null,
    verloading: state.auth.verloading,
    hpRedirect: state.auth.hpRedirect,
    vererror: state.auth.vererror
});

const mapDispatchToProps = (dispatch) =>
({
    onVerify: (code) => dispatch(actions.verifyMsisdn(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);