import { useState, useEffect } from 'react'
import "./Historymatches.css"

export default function Historymatches(props) {

	const [homescore, sethomescore] = useState();
	const [awayscore, setawayscore] = useState();
	const [postponed, SetPostponed] = useState('');
	const [data, Setdata] = useState('');

	useEffect(() => {
		if (props.hometeamscore > props.awayteamscore) {
			sethomescore('colorblue')
			setawayscore('colorred')
		} else if (props.hometeamscore < props.awayteamscore) {
			sethomescore('colorred')
			setawayscore('colorblue')
		} else if (props.hometeamscore && props.awayteamscore == -1) {
			SetPostponed('Postponed');
			Setdata('hide');
		}
		else {
			sethomescore('colorblue')
			setawayscore('colorblue')
		}
	}, [props.hometeamscore, props.awayteamscore])


	return (
		<div className="hts-matches">
			<div className="matches-data">
				<div className="data-teamblock">
					<img className="teamblock__image" src={props.hometeamphoto} />
					<div className="teamblock__teamname">{props.hometeam}</div>
				</div>
				<div className={"data-score " + data}>
					<div className={homescore}>
						{props.hometeamscore}
					</div>
					-
					<div className={awayscore}>
						{props.awayteamscore}
					</div>
				</div>
				<div className={"data-scores " + postponed}>Postponed</div>
				<div className="data-teamblock">
					<img className="teamblock__image" src={props.awayteamphoto} />
					<div className="teamblock__teamname">{props.awayteam}</div>
				</div>
			</div>

		</div>

	)
}
