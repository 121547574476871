import { useEffect, useState } from "react";
import LogoComponent from "./LogoComponent";
import { onChangeHandler } from "../util.js/httpService";
import { Link, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';
import { pages_user } from "../Api";
import axios from "axios";
import Slider from "./Slider/Slider";
// import banner from '../assets/images/LANDSCAPE.gif';

const HeaderComponent = (props) => {

    // const [banner, setBanner] = useState(null);

    const [user, setUser] = useState({});
    const [visible, setVisible] = useState(false);
    const [payload, setPayload] = useState({
        mobileNumber: "",
        password: "",
        type: 1
    });
    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(true);
    const { pathname } = useLocation();
    const [navOpen, setNavOpen] = useState("");

    useEffect(() => {
        props.onTryAutoSignup();

        // Get Portrait Promo image
        //     axios.get(pages_user("promotion", false))
        //         .then((response) => {
        //             if (response?.data?.status === 1) {
        //                 response?.data?.items?.map((item) => {
        //                     if (item.title === 'Landscape Promo') {
        //                         setBanner(item.carouselImage)
        //                     }
        //                 })
        //             }
        //         })
        //         .catch(() => {
        //         });
    }, []);

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("setUserData") || '{}'));
    }, [localStorage.getItem("setUserData")]);

    const LoginFunc = () => {
        const errors = Object.keys(payload)
            .map((input) => payload[input] === "" ? input : true)
            .filter((f) => f !== true);
        if (errors.length)
            setErr(`${errors[0]} is required`);
        else {
            setLoading(true);
            props.onAuth(payload.mobileNumber, payload.password);

            setErr(props.error ? props.error : "")
        }
    }

    const toggleOpen = () => {
        navOpen == "" ? setNavOpen('nav-open') : setNavOpen("");
    }

    return (
        <>
            <section id="site_head_main" className={navOpen}>
                <header>
                    <div className="grid grid-pad">
                        <div className="col-3-12">
                            <div className="content">
                                <div id="logo"><Link to="./"></Link></div>
                            </div>
                        </div>

                        {props.isAuthenticated ?
                            <div className="col-9-12">
                                <div className="login_home_auth">
                                    <span className="pf-user-name">Hi, {user.firstName} {user.lastName}</span>
                                    <div className="_mobile_disable users_profile">
                                        <span className="name_profile user_icon">
                                            <Link to="/bethistory" className={`${pathname === '/bethistory' ? 'active' : ''}`}>My Bets<i className="fa fa-soccer-ball-o"></i></Link>
                                        </span>
                                        <span className="name_profile my_profile">
                                            <Link to="/profile" className={`${pathname === '/profile' ? 'active' : ''}`}>My Account<i className="fa fa-newspaper-o"></i></Link>
                                        </span>
                                        <span className="name_profile my_profile">
                                            <a onClick={() => props.logout()}>Logout<i className="fa fa-logout"></i></a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            : <div className="col-9-12">
                                <div className="login_home_auth">
                                    <div className="_mobile_disable">
                                        <LogoComponent />
                                        <input type="tel" className="tel" placeholder="Mobile Number"
                                            value={payload['mobileNumber']} name="mobileNumber" onChange={(e) => setPayload(onChangeHandler(e, payload))} />
                                        <input type={!visible ? "password" : "text"} placeholder="Password" id="password" className="masked"
                                            value={payload['password']} name="password" onChange={(e) => setPayload(onChangeHandler(e, payload))} />
                                        <span className="eye-icon" style={{ position: 'absolute', top: 5, right: 230 }} onClick={() => setVisible(!visible)}>
                                            <i className={`fa ${!visible ? 'fa-eye' : 'fa-eye-slash'}`} id="eye"></i>
                                        </span>

                                        <button disabled={!loading} className="_c-signin" onClick={() => LoginFunc()}>login</button>

                                        <Link to="/register"><button className="_c-signin signup_button">Sign Up</button></Link>
                                        {err && <div style={{ position: 'absolute', top: 45, textAlign: 'left', width: '100%', fontSize: 12, color: '#ccc', paddingLeft: 10 }}>{err}</div>}
                                    </div>

                                </div>
                            </div>}

                        <div className="col-1-1">
                            <div className="content">
                                <nav>
                                    <ul>
                                        <li className={`${pathname === '/' ? 'active' : ''}`}><Link to="/">Home</Link></li>
                                        <li className={`${pathname === '/results' ? 'active' : ''}`}><Link to="/results">Results</Link></li>
                                        <li className={`${pathname === '/gameterms' ? 'active' : ''}`}><Link to="/gameterms">Game Terms</Link></li>
                                        <li className={`${pathname === '/tnc' ? 'active' : ''}`}><Link to="/tnc">Terms & Conditions</Link></li>
                                        <li className={`${pathname === '/howtoplay' ? 'active' : ''}`}><Link to="/howtoplay">How To Play</Link></li>
                                        <li className={`${pathname === '/promo' ? 'active' : ''}`}><Link to="/promo">Promos</Link></li>
                                        <li className={`${pathname === '/contacts' ? 'active' : ''}`}><Link to="/contacts"> Contact us</Link></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="jackpot-banner">
                            <div className="banner"><div className="grid grid-pad slider">
                                <div className="col-1-1">
                                    <div className="content">
                                        {/* <Link to="/promo"><img className='promo_banner_landscape' src={banner} alt='Promo Landscape' /></Link> */}
                                        <Slider />
                                    </div>
                                </div>
                            </div></div></div>
                    </div>

                    <div className="head-main" id="mobile-header">

                        {props.isAuthenticated
                            ?
                            <></>
                            :
                            <div className="_mobile_auth">
                                <button className="_c-signin"><Link to="/login">Login</Link></button>
                                <button className="_c-signin signup_button"><Link to="/register">Sign Up</Link></button>
                            </div>
                        }

                        <div id="span-bars" className="navbar navbar-dark bg-dark box-shadow">
                            <div className="container d-flex justify-content-between">
                                <a className="nav-button ml-auto mr-4" onClick={() => toggleOpen()}>
                                    <span id="nav-icon3">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div className="fixed-top dineuron-menu">
                            <div className="flex-center p-5">
                                <ul className="nav flex-column">
                                    {
                                        props.isAuthenticated ?
                                            <li className="nav-item delay-0">
                                                <div className="account-prof">
                                                    <Link to="/bethistory" onClick={() => toggleOpen()}><button className="logOut">My Bets</button></Link>
                                                    <Link to="/profile" onClick={() => toggleOpen()}><button className="logOut">My Account</button></Link>
                                                </div>
                                            </li>
                                            :
                                            <></>
                                    }
                                    <li className="nav-item delay-1"><Link className="nav-link" to="/" onClick={() => toggleOpen()}>Home</Link></li>
                                    <li className="nav-item delay-2"><Link className="nav-link" to="/results" onClick={() => toggleOpen()}>Results</Link></li>
                                    <li className="nav-item delay-3"><Link className="nav-link" to="/gameterms" onClick={() => toggleOpen()}>Game Terms</Link></li>
                                    <li className="nav-item delay-4"><Link className="nav-link" to="/tnc" onClick={() => toggleOpen()}>Terms & Conditions</Link></li>
                                    <li className="nav-item delay-5"><Link className="nav-link" to="/howtoplay" onClick={() => toggleOpen()}>How To Play</Link></li>
                                    <li className="nav-item delay-6"><Link className="nav-link" to="/promo" onClick={() => toggleOpen()}>Promos</Link></li>
                                    <li className="nav-item delay-7"><Link className="nav-link" to="/about" onClick={() => toggleOpen()}>About us</Link></li>
                                    <li className="nav-item delay-8"><Link className="nav-link" to="/contacts" onClick={() => toggleOpen()}>Contact us</Link></li>
                                    <li className="nav-item delay-9"><Link className="nav-link" to="/privacy" onClick={() => toggleOpen()}>Privacy Policy</Link></li>
                                    {props.isAuthenticated ?
                                        <li className="nav-item delay-10"><button className="logOut" onClick={() => props.logout()}>logout</button></li>
                                        :
                                        <></>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                </header>
            </section>
        </>
    );
}

const mapStateToProps = (state) =>
({
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    balance: state.auth.wallet
});

const mapDispatchToProps = (dispatch) =>
({
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onAuth: (username, password) => dispatch(actions.auth(username, password)),
    logout: () => dispatch(actions.logout()),
    getWallet: () => dispatch(actions.getWallet())
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);