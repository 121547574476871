import { useEffect, useState } from 'react';
import Pool from "../../components/pools/Pool";
import { getPools, getTicketDetails, pages_user } from "../../Api";
import axios from 'axios';
import Betslip from "../../components/betslip/Betslip";
import '../../components/betslip/Betslip.css';
import './Home.css';
import { connect } from 'react-redux';
import Loader, { ErrorMessage } from '../../components/Loader/Loader';
// import banner from '../../assets/images/PORTRAIT.gif';
import { Link } from 'react-router-dom';
import Popup from '../../components/Popup/Popup';
import ReactGA from "react-ga4";

function Home(props) {

    const [banner, setBanner] = useState(null);

    useEffect(() => {
        // Google Analytics
        ReactGA.send({ hitType: "pageview", page: "/" });

        // Get Portrait Promo image
        axios.get(pages_user("promotion", false))
            .then((response) => {
                if (response?.data?.status === 1) {
                    response?.data?.items?.map((item) => {
                        if (item.title === 'Portrait Promo') {
                            setBanner(item.carouselImage)
                        }
                    })
                }
            })
            .catch(() => {
            });
    }, [])

    let matches_numb = [];
    let groupArrays = [];

    const [loader, setLoader] = useState(null);
    const [responsee, setresponsee] = useState([]);
    const [pools, setpools] = useState([]);
    const [filternumb, setfilter] = useState(0);
    const [btnactivate, setbtnactivate] = useState(null);
    const [inactivePools, setInactivePools] = useState(false);
    const [ticketDetails, setTicketDetails] = useState("");

    const [invalidRefPopup, setInvalidRefPopup] = useState(false);
    const [closedPoolPopup, setClosedPoolPopup] = useState(false);

    const moment = require('moment');

    const handlefilterbtn = (item, index) => {
        if (btnactivate === index && filternumb === item) {
            setbtnactivate(null);
            setfilter(0)
        }
        else {
            setbtnactivate(index);
            setfilter(item);
        }
    }

    useEffect(() => {
        let url = getPools(1, '', '', '');
        let urlInactive = getPools(0, '', '', '');
        setLoader(Loader);

        axios.get(url)
            .then((response) => {
                setresponsee(response.data.pools);
                setLoader(null);
                if (response?.data?.pools?.length == 0) {
                    setLoader(Loader);
                    setInactivePools(true);
                    axios.get(urlInactive)
                        .then((response) => {
                            setresponsee(response.data.pools);
                            setLoader(null);
                        })
                        .catch(() => {
                            setLoader(ErrorMessage());
                        })
                }
                else {
                    setInactivePools(false);
                }
            })
            .catch(() => {
                setLoader(ErrorMessage());
            })
    }, [])

    useEffect(() => {
        let url;
        let urlInactive;
        if (filternumb > 0) {
            url = getPools(1, '', '', filternumb);
            urlInactive = getPools(0, '', '', filternumb);
        }
        else {
            url = getPools(1, '', '', '');
            urlInactive = getPools(0, '', '', '');
        }
        setLoader(Loader);
        axios.get(url)
            .then((response) => {
                setpools(response.data.pools);
                setLoader(null);

                if (response?.data?.pools?.length == 0) {
                    setLoader(Loader);
                    setInactivePools(true);
                    axios.get(urlInactive)
                        .then((response) => {
                            setpools(response.data.pools);
                            setLoader(null);
                        })
                        .catch(() => {
                            setLoader(ErrorMessage());
                        })
                }
                else {
                    setInactivePools(false);
                }
            })
            .catch(() => {
                setLoader(ErrorMessage());
            })
    }, [filternumb])

    for (var i = 0; i < responsee.length; i++) {
        matches_numb.push(responsee[i].pool_nb_matches)
    }
    let filterable_matches = new Set(matches_numb);
    matches_numb = [...filterable_matches].sort();

    const groups = pools.reduce((groups, game) => {
        const date = moment(game.pool_enddate).format('dddd, D MMMM YYYY');
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(game);
        return groups;
    }, {});

    groupArrays = Object.keys(groups).map((date) => {
        return {
            date: date,
            pools: groups[date]
        };
    });

    const searchRef = (reference) => {
        let ticketDetailsObject = {
            reference: reference ? reference : "",
            ticketId: 0,
            userToken: localStorage.getItem("token"),
        };
        axios
            .post(getTicketDetails, ticketDetailsObject)
            .then((response) => {
                if (response.data.status === -1) {
                    setClosedPoolPopup(true);
                }
                else if (response.data.status === 0) {
                    setTicketDetails(response.data);
                    window.location.href = "/pools/" + response.data.pool.pool_id;
                }
            })
            .catch((e) => {
                console.log(e);
                setInvalidRefPopup(true);
            });
    }


    return (
        <div className="game-container__wrapper">
            <div className='betSlip_promo_section'>
                <Betslip activeButtonsList={props.activeButtonsList} poolItem={ticketDetails ? ticketDetails.pool : ''} searchRef={searchRef} />
                <Link to="/promo"><img className='promo_banner' src={banner} alt='Promo Portrait' /></Link>
            </div>
            <div className="game-container__wrapper-pools">
                {inactivePools ?
                    <>
                        <div className='myBets__betDate'>
                            No active pools available!
                        </div>
                        <div className="game__inactivePools-titleWrapper">
                            <div className="game__inactivePools-title">
                                Closed Pools
                                <div className="game__inactivePools-matchcount">
                                    <span className="game__inactivePools-count"> {pools.length}</span>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <></>
                }
                {pools?.length > 0 ?
                    <div className="filterable-container">

                        {matches_numb
                            .sort((a, b) => a.pool_nb_matches <= b.pool_nb_matches ? 1 : -1)
                            .map((item, index) => {
                                return (
                                    <div key={index} className={index === btnactivate ? "filterable-container__matchwrappers activatebtn" : "filterable-container__matchwrappers"} onClick={() => handlefilterbtn(item, index)}><span className="filterable-container__matchnumbers">{item}</span></div>
                                )
                            })
                        }

                    </div>
                    :
                    <div className='myBets__betDate'>
                        No Pools to Display
                    </div>
                }
                {groupArrays ? groupArrays.sort((a, b) => Date.parse(a.date) > Date.parse(b.date) ? -1 : 1).map((value, valueIndex) => (
                    <div key={valueIndex}>
                        <div className="matchdate-formated">
                            {value.date}
                        </div>

                        {value.pools.map((pool, tagIndex) => (
                            <div key={tagIndex}>
                                <Pool poolitem={pool} inactivePools={inactivePools} />
                            </div>
                        ))}
                    </div>
                )) : ''}

                {loader}

            </div>

            {closedPoolPopup ?
                <Popup title="Pool Closed" data="Please enter a valid pool bet!" options={["OK"]} showPopup={setClosedPoolPopup} />
                :
                <></>
            }
            {invalidRefPopup ?
                <Popup title="Invalid Reference" data="Please enter a valid reference!" options={["OK"]} showPopup={setInvalidRefPopup} />
                :
                <></>
            }

        </div>

    )
}

const mapStateToProps = state => {
    return {
        activeButtonsList: state.auth.activeButtonsList
    };
};

export default connect(mapStateToProps)(Home);