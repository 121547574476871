import './App.css';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Index from './containers/Index';
import Login from './containers/Login';
import Register from './containers/Register';
import Recovery from './containers/Recovery';
import Verification from './containers/Verification';
import Reset from './containers/Reset';
import MyBets from './containers/MyBets/MyBets';
import Tnc from './containers/Tnc';
import Promo from './containers/Promo';
import GameTerms from './containers/GameTerms';
import Contact from './containers/Contact';
import Privacy from './containers/Privacy';
import About from './containers/About';
import Layout from './components/Layout';
import OpenLayout from './components/OpenLayout';
import StaticLayout from './components/StaticLayout';
import ReactGA from "react-ga4";
import HowToPlay from './containers/HowToPlay';
import MatchesList from './containers/MatchesList/MatchesList';
import Home from './containers/Home/Home';
import Results from './containers/Results/Results';
import Profile from './containers/Profile';

ReactGA.initialize([
	{
		trackingId: "G-Z27MH1X75G",
	},
]);

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route element={<RequireAuth><Layout /></RequireAuth>}>
					<Route path="/profile" element={<Profile />} />
					<Route path="/bethistory" element={<MyBets />} />
				</Route>
				<Route element={<Layout />}>
					<Route path="/results" element={<Results />} />
				</Route>

				<Route path="/welcome" element={<AuthUser><Index /></AuthUser>} />
				<Route path="/login" element={<AuthUser><Login /></AuthUser>} />
				<Route path="/register" element={<AuthUser><Register /></AuthUser>} />
				<Route path="/recovery" element={<AuthUser><Recovery /></AuthUser>} />
				<Route path="/verification" element={<AuthUser><Verification /></AuthUser>} />
				<Route path="/reset" element={<AuthUser><Reset /></AuthUser>} />
				<Route element={<OpenLayout />} >

					<Route index element={<Home />} />
					<Route path="/pools/:poolId" element={<MatchesList />} />
					<Route path="/poolBet/:reference" element={<MatchesList />} />

					<Route element={<StaticLayout />} >
						<Route path="/tnc" element={<Tnc />} />
						<Route path="/howtoplay" element={<HowToPlay />} />
						<Route path="/promo" element={<Promo />} />
						<Route path="/gameterms" element={<GameTerms />} />
						<Route path="/contacts" element={<Contact />} />
						<Route path="/about" element={<About />} />
						<Route path="/privacy" element={<Privacy />} />
					</Route>
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

function RequireAuth(children) {
	const isLoggedIn = localStorage.getItem("IsLoggedIn");
	const location = useLocation();

	if (["false", null].includes(isLoggedIn)) {
		return <Navigate to="/welcome" state={{ from: location }} replace />;
	}

	return children.children;
}

function AuthUser(children) {
	const isLoggedIn = localStorage.getItem("IsLoggedIn");

	const location = useLocation();

	if (isLoggedIn === "true") {
		return <Navigate to="/" state={{ from: location }} replace />;
	}

	return children.children;
}

export default App;
