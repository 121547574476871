import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    activeButtonsList: null,
    token: null,
    error: null,
    regerror: null,
    vererror: null,
    loading: false,
    loading2: false,
    verloading: false,
    verify: false,
    hpRedirect: false,
    redirectVerify: false,
    successMessage: null,
    wallet: null
};

const updateActiveButtons = (state, action) => {
    return updateObject(state, { activeButtonsList: action.activeButtonsList });
}

const authStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        error: null,
        loading: false,
        loading2: false,
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const authVerStart = (state, action) => {
    return updateObject(state, {
        verloading: true
    });
};

const authVerFail = (state, action) => {
    return updateObject(state, {
        vererror: action.vererror,
        verloading: false
    });
};

const authRegFail = (state, action) => {
    return updateObject(state, {
        regerror: action.regerror,
        regloading: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { token: null });
};

const updateWallet = (state, action) => {
    return updateObject( state, {
        wallet: action.wallet,
        promoWallet: action.promoWallet,
    } );
};

const regStart = (state, action) => {
    return updateObject(state, { regerror: null, regloading: true });
};

const updateMessage = ( state, action ) => {
    return updateObject( state, { ticketMessage: action.ticketMessage, status: action.status } );
};

const redirectVerify = (state, action) => {
    return updateObject(state, {
        redirectVerify: action.redirect,
        loading: false,
    });
};

const verifySuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        error: null,
        loading: false,
        hpRedirect: true
    });
};

const registerSuccess = (state, action) => {
    return updateObject(state, {
        verify: true,
        regloading: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ACTIVE_BUTTONS: return updateActiveButtons(state, action);
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.VER_START: return authVerStart(state, action);
        case actionTypes.VER_FAIL: return authVerFail(state, action);
        case actionTypes.AUTH_REG_FAIL: return authRegFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.REG_START: return regStart(state, action);
        case actionTypes.REDIRECT_VERIFY: return redirectVerify(state, action);
        case actionTypes.VERIFY_SUCCESS: return verifySuccess(state, action);
        case actionTypes.REGISTER_SUCCESS: return registerSuccess(state, action);
        case actionTypes.UPDATE_WALLET: return updateWallet(state, action);
        case actionTypes.UPDATE_MESSAGE: return updateMessage(state, action);
        default:
            return state;
    };
}

export default reducer;