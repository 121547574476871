import { useState, useEffect } from 'react'
import Previous from "../../components/previous/Previous";
import "./Statistics.css"
import image from "../../assets/images/unkown-avatar.jpg"
import Loader, { ErrorMessage } from '../../components/Loader/Loader';
import { latestresult, previousresult } from "../../Api";
import axios from 'axios';

export default function Statistics(props) {

    const [hometeamname, setHomeTeamName] = useState(null);
    const [awayteamname, setAwayTeamName] = useState(null);
    const [hometeamlogo, setHomeTeamLogo] = useState(null);
    const [awayteamlogo, setAwayTeamLogo] = useState(null);

    const [loader, setLoader] = useState(null);

    const [hometeamid, setHomeTeamId] = useState('');
    const [awayteamid, setAwayTeamId] = useState('');

    const [btnstate, setBtnSate] = useState('bothteams');
    const [indexbtn, setIndexBtn] = useState('index2');

    const [previousmeetings, setPreviousMeetings] = useState(0);
    const [awayteamresults, setAwayTeamResults] = useState(0);
    const [hometeamresults, setHomeTeamResults] = useState(0);

    const [statushometeam, setStatusHomeTeam] = useState(null);
    const [statusawayteam, setStatusAwayTeam] = useState(null);
    const [hometeamvenue, setHomeTeamVenue] = useState(null);
    const [awayteamvenue, setAwayTeamVenue] = useState(null);

    useEffect(() => {
        if (props.match) {
            setHomeTeamName(props.match.homeTeam.name)
            setAwayTeamName(props.match.awayTeam.name)
            setHomeTeamId(props.match.homeTeam.id)
            setAwayTeamId(props.match.awayTeam.id)
            setHomeTeamLogo(props.match.homeTeam.mediaList[0] ? props.match.homeTeam.mediaList[0].url : image)
            setAwayTeamLogo(props.match.awayTeam.mediaList[0] ? props.match.awayTeam.mediaList[0].url : image)
        }
    }, [props.match])

    useEffect(() => {
        if (hometeamid && awayteamid) {
            let url = previousresult(hometeamid, awayteamid);
            setLoader(Loader);
            axios.get(url)
                .then((response) => {
                    setPreviousMeetings(response.data.statistics.previousMatches);
                    setLoader(null);
                }).catch(() => {
                    setLoader(ErrorMessage());
                })
        }

    }, [hometeamid, awayteamid]);

    useEffect(() => {
        if (hometeamid) {
            let url = latestresult(hometeamid);
            setLoader(Loader);
            axios.get(url)
                .then((response) => {
                    setHomeTeamResults(response.data.statistics.latestResults)
                    setLoader(null);
                }).catch(() => {
                    setLoader(ErrorMessage());
                })
        }

    }, [hometeamid]);

    useEffect(() => {
        if (awayteamid) {
            let url = latestresult(awayteamid);
            setLoader(Loader);
            axios.get(url)
                .then((response) => {
                    setAwayTeamResults(response.data.statistics.latestResults)
                    setLoader(null);
                }).catch(() => {
                    setLoader(ErrorMessage());
                })
        }

    }, [awayteamid]);

    const handleShowHide = (btnchoice, indexbtn) => {
        setIndexBtn(indexbtn);
        setBtnSate(btnchoice);
    }

    useEffect(() => {
        let tmparray1 = [];
        let tmparray2 = [];
        if (hometeamresults) {
            hometeamresults.slice(0, 5).map((value) => {
                tmparray1.push(value.venue === 'H' ? value.score_home > value.score_away ? "W" : value.score_home < value.score_away ? "L" : "D" : value.score_home > value.score_away ? "L" : value.score_home < value.score_away ? "W" : "D")
                tmparray2.push(value.venue)
            })
        } else return
        setStatusHomeTeam(tmparray1.length > 0 ? tmparray1.join("-") : "No Results Found!")
        setHomeTeamVenue(tmparray2.join("-"))
    }, [hometeamresults])

    useEffect(() => {
        let tmparray1 = [];
        let tmparray2 = [];
        if (awayteamresults) {
            awayteamresults.slice(0, 5).map((value) => {
                tmparray1.push(value.venue === 'H' ? value.score_home > value.score_away ? "W" : value.score_home < value.score_away ? "L" : "D" : value.score_home > value.score_away ? "L" : value.score_home < value.score_away ? "W" : "D")
                tmparray2.push(value.venue)
            })
        } else
            return
        setStatusAwayTeam(tmparray1.length > 0 ? tmparray1.join("-") : "No Results Found!")
        setAwayTeamVenue(tmparray2.join("-"))
    }, [awayteamresults])

    return (

        // <div className="game-container__wrapper">
        <div className="content-container">
            <div>
                <span className='matchList__back-button' onClick={() => { handleShowHide("bothteams", "index2"); props.handleclick(false) }}></span>
                <div className="btns-statistics-wrapper">
                    <div className={indexbtn === 'index1' ? "btns-statistics-wrapper-team activebtnchoose" : "btns-statistics-wrapper-team"}
                        onClick={() => handleShowHide("hometeam", "index1")}
                    >
                        <img src={hometeamlogo} alt="team" className="btns-statistics-wrapper-team__image" />
                        <span className="btns-statistics-wrapper-team__name teamname">{hometeamname}</span>
                    </div>
                    <div className={indexbtn === 'index2' ? "btns-statistics-wrapper-team activebtnchoose" : "btns-statistics-wrapper-team"}
                        onClick={() => handleShowHide("bothteams", "index2")}
                    >
                        <span className="btns-statistics-wrapper-team__name">view both teams</span>
                    </div>
                    <div className={indexbtn === 'index3' ? "btns-statistics-wrapper-team activebtnchoose" : "btns-statistics-wrapper-team"}
                        onClick={() => handleShowHide("awayteam", "index3")}
                    >
                        <img src={awayteamlogo} alt="team" className="btns-statistics-wrapper-team__image" />
                        <span className="btns-statistics-wrapper-team__name teamname">{awayteamname}</span>
                    </div>
                </div>
                <div className={btnstate === 'bothteams' ? "match-preview" : 'showdata'}>
                    <div className="match-preview-wrapper">
                        <span className="match-preview-wrapper__string">Match</span>
                    </div>
                    <div className="match-preview-wrapper match-string-space">
                        <span className="match-preview-wrapper__string match-string">{hometeamname} vs {awayteamname}</span>
                    </div>
                </div>
                <div className="matchesinfo-container">
                    <div className={btnstate === 'awayteam' ? "homeaway-container showdata" : "homeaway-container"}>
                        <div className="homeaway-container-winstate">
                            <span className="homeaway-container-winstate__text">{statushometeam}</span>
                        </div>
                        <div className="homeaway-container-winstate">
                            <span className="homeaway-container-winstate__text">{hometeamvenue}</span>
                        </div>
                    </div>
                    <div className={btnstate === 'awayteam' || btnstate === 'bothteams' ? "showdata" : 'teamblock'}>
                        <img src={hometeamlogo} alt="team" className="team-photo-preview" />
                        <span className="teamlogo-name">{hometeamname}</span>
                    </div>
                    <div className="homeaway-container info">
                        <div className="match-preview-wrapper">
                            <span className="match-preview-wrapper__string">last 5<br /> (home/away)</span>
                        </div>
                    </div>
                    <div className={btnstate === 'hometeam' || btnstate === 'bothteams' ? "showdata" : 'teamblock'}>
                        <img src={awayteamlogo} alt="team" className="team-photo-preview" />
                        <span className="teamlogo-name">{awayteamname}</span>
                    </div>
                    <div className={btnstate === 'hometeam' ? "homeaway-container showdata" : "homeaway-container"}>
                        <div className="homeaway-container-winstate">
                            <span className="homeaway-container-winstate__text">{statusawayteam}</span>
                        </div>
                        <div className="homeaway-container-winstate">
                            <span className="homeaway-container-winstate__text">{awayteamvenue}</span>
                        </div>
                    </div>

                </div>

                <div className="statistics-sections">
                    <span className="statistics-section__title">Previous Meetings</span>
                </div>
                <div className="teamblock">
                    {
                        Object.keys(previousmeetings).length > 0 ? previousmeetings.map((value) => {
                            return (
                                <Previous
                                    date={value.match_date.slice(0, value.match_date.indexOf(' '))}
                                    league={value.league}
                                    team1={value.homeTeam}
                                    score1={value.score_home}
                                    score2={value.score_away}
                                    team2={value.awayTeam}
                                />)
                        })
                            : 'No results found!'
                    }
                    {loader}
                </div>
                <div className={btnstate === 'awayteam' ? 'showdata' : ''}>
                    <div className="statistics-sections">
                        <span className="statistics-section__title">{hometeamname} latest result</span>
                    </div>
                    <div className="teamblock">
                        {
                            Object.keys(hometeamresults).length > 0 ? hometeamresults.map((value) => {
                                return (
                                    <Previous
                                        date={value.match_date.slice(0, value.match_date.indexOf(' '))}
                                        team1={value.homeTeam}
                                        score1={value.score_home}
                                        score2={value.score_away}
                                        team2={value.awayTeam}
                                        venue={value.venue}
                                        latestresult={true}
                                    />)
                            })
                                : 'No results found!'
                        }
                        {loader}
                    </div>
                </div>
                <div className={btnstate === 'hometeam' ? 'showdata' : ''}>
                    <div className="statistics-sections">
                        <span className="statistics-section__title">{awayteamname} latest result</span>
                    </div>
                    <div className="teamblock">
                        {
                            Object.keys(awayteamresults).length > 0 ? awayteamresults.map((value) => {

                                return (

                                    <Previous
                                        date={value.match_date.slice(0, value.match_date.indexOf(' '))}
                                        team1={value.homeTeam}
                                        score1={value.score_home}
                                        score2={value.score_away}
                                        team2={value.awayTeam}
                                        venue={value.venue}
                                        latestresult={true}
                                    />)
                            })
                                : 'No results found!'}
                        {loader}
                    </div>
                </div>
            </div>
        </div>
        // </div>
    )
}
