import axios from "axios";

const { REACT_APP_API_ENDPOINT } = process.env;

export const baseurl = REACT_APP_API_ENDPOINT;

axios.defaults.baseURL = REACT_APP_API_ENDPOINT;

const http = axios.create({
    baseURL: baseurl,
});

http.interceptors.request.use(
    (config) => {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const onChangeHandler = (event, payload) => {
    const newValue = {};
    newValue[event.target.name] = event.target.value;
    return ({ ...payload, ...newValue });
}
