import { Link } from "react-router-dom";

const FootComponent = () => {

    return (<footer className="home">

        <div className="grid grid-pad">
            <div className="footer_nav">
                <div className="col-1-3">
                    <div className="content aside-content">
                        <div className="gc_brand">
                            <span className="nla-logo"></span>
                        </div>
                        <div className="">Footycash.com.gh operations are licensed under the laws of Ghana and approved by the National Lottery Authority of Ghana</div>

                        <div className="grp-s-div">
                            <div className="gc_brand">
                                <span className="pluse-18"></span></div>
                            <div className="">Players of the game must be 18 years of age or older. Please note that gambling can be addictive and must be exercised with caution</div></div>
                    </div>
                </div>
                <div className="col-1-3">
                    <div className="content">
                        <nav className="footer-sub">
                            <ul>
                                <li><Link to="/">Pool Bet</Link></li>
                                <li><Link to="/results">Results</Link></li>
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/gameterms">Game Terms</Link></li>
                                <li><Link to="/tnc">Terms & Conditions</Link></li>
                                <li><Link to="/howtoplay">How To Play</Link></li>
                                <li><Link to="/promo">Promos</Link></li>
                                <li><Link to="/contacts">Contact Us</Link></li>
                                <li><Link to="/privacy">Privacy Policy</Link></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-1-3">
                    <div className="content footer-contact">
                        <p>Telephone:<br />+233 596921619</p><br />
                        <p>Email: <br />support@footycash.com.gh
                            <br />marketing.ghana@footycash.com.gh
                        </p>

                        <div className="footer-social-icons">
                            <a href="http://www.facebook.com/footycashgh/" target="_blank" className="bg-social_icon_sprite">f</a>
                            <a href="http://www.instagram.com/footycashgh/" target="_blank" className="bg-social_icon_sprite instagram">d</a>
                            <a href="http://www.youtube.com/c/footycashgh/" target="_bank" className="bg-social_icon_sprite youtube">d</a>
                        </div>
                    </div>
                </div></div>
            <div className="col-1-1">
                <div className="content">
                    &copy; 2022 Footy Cash. All rights reserved.
                </div>
            </div>
        </div>

    </footer>);
}

export default FootComponent;