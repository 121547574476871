import { useEffect, useState } from 'react';
import './MatchesList.css';
import axios from 'axios';
import { getPoolDetailsApi, getBetPoolId, getTicketDetails, pages_user } from '../../Api';
import * as actions from "../../store/actions";
import { connect } from 'react-redux';
import moment from 'moment';
import Loader, { ErrorMessage } from '../../components/Loader/Loader';
import { faChartSimple, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Statistics from "../statistics/Statistics";
import { Link, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Betslip from '../../components/betslip/Betslip';
// import banner from '../../assets/images/PORTRAIT.gif';
import Popup from '../../components/Popup/Popup';

function MatchesList(props) {

    const [banner, setBanner] = useState(null);

    const [loader, setLoader] = useState(null);
    const [btn_active, setBtnActive] = useState(props.activeButtonsList ? props.activeButtonsList : null);
    const [poolId, setPoolId] = useState(props.poolId);
    const [poolDetails, setPoolDetails] = useState(null);
    const [pagevisible, setPageVisible] = useState('');
    const [visibility, setVisibility] = useState(false);
    const [matchstats, setMatchStats] = useState(null);
    const [betreference, setBetReference] = useState('');

    const [ticketDetails, setTicketDetails] = useState(null);
    const [invalidRefPopup, setInvalidRefPopup] = useState(false);
    const [closedPoolPopup, setClosedPoolPopup] = useState(false);
    const [inactivePools, setInactivePools] = useState(useLocation().state);

    let params = useParams();

    useEffect(() => {
        // Get Portrait Promo image
        axios.get(pages_user("promotion", false))
            .then((response) => {
                if (response?.data?.status === 1) {
                    response?.data?.items?.map((item) => {
                        if (item.title === 'Portrait Promo') {
                            setBanner(item.carouselImage)
                        }
                    })
                }
            })
            .catch(() => {
            });
    }, [])

    // If pool id is sent through the URL
    useEffect(() => {
        if (params.poolId)
            setPoolId(params.poolId);
    }, [params]);

    useEffect(() => {
        if (poolDetails) {
            props.updateActiveButtons([]);
            setBtnActive(Array(poolDetails.pool_nb_matches).fill('').map(row => new Array(poolDetails.pool_betypes_settings.length).fill('')));

            // Checks pool status if active or inactive when the pool item changes
            if (poolDetails.pool_status != null) {
                poolDetails.pool_status == 1 ? setInactivePools(false) : setInactivePools(true);
            }
        }

    }, [poolDetails]);

    useEffect(() => {
        if (betreference) {
            let object = {
                reference: betreference,
                ticketId: 0,
                userToken: localStorage.getItem('token')
            }
            axios.post(getTicketDetails, object).then((response) => {
                if (response.data.status === 0) {
                    setTicketDetails(response.data.matches)
                }
            }).catch((e) => {
                console.log(e);
            })
        }
    }, [poolId, betreference])

    useEffect(() => {
        if (poolId) {
            getPoolDetails(poolId);
            if (localStorage.getItem('token')) {
                getPoolBetRef();
            }
        }
    }, [poolId])

    useEffect(() => {
        if ((!props.activeButtonsList) && (poolDetails)) {
            setBtnActive(Array(poolDetails.pool_nb_matches).fill('').map(row => new Array(poolDetails.pool_betypes_settings.length).fill('')));
        }
    }, [props.activeButtonsList]);

    const getPoolDetails = (poolId) => {
        let url = getPoolDetailsApi(poolId);
        setLoader(Loader);
        axios.get(url)
            .then((response) => {
                setPoolDetails(response.data);
                setBtnActive(Array(response.data.pool_nb_matches).fill('').map(row => new Array(response.data.pool_betypes_settings.length).fill('')));
                setLoader(null);
            }).catch((e) => {
                setLoader(ErrorMessage());
            })
    }

    const getPoolBetRef = () => {
        let object = {
            "poolId": parseInt(poolId),
            "userToken": localStorage.getItem('token')
        }
        axios.post(getBetPoolId, object).then((response) => {
            if (response.data.status === 0) {
                setBetReference(response.data.playerBet.ref)
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const handleChange = (matchId, settingId) => {
        if (btn_active) {
            let copy = [...btn_active];
            copy[matchId][settingId] == '' ? copy[matchId][settingId] = poolDetails.matches[matchId].matchid : copy[matchId][settingId] = '';
            setBtnActive(copy);
            props.updateActiveButtons(copy);
        }
    }

    useEffect(() => {
        if (ticketDetails) {
            showSavedBet();
        }
    }, [ticketDetails]);

    const showSavedBet = () => {
        if (betreference) {
            let tempActive = Array(poolDetails.pool_nb_matches).fill('').map(row => new Array(poolDetails.pool_betypes_settings.length).fill(''));
            ticketDetails.map((match, matchIndex) => {
                let betSelection = match.betselection.split(",").map(Number);
                betSelection.map((selection) => {
                    tempActive[matchIndex][selection - 1] = match.matchid;
                })
            })
            setBtnActive(tempActive);
            props.updateActiveButtons(tempActive);
        }
    }

    const handleStatistics = (pagestate, match) => {
        setMatchStats(match);
        if (pagestate === true) {
            setPageVisible('matchlistnovisible')
            setVisibility(true);
        } else {
            setPageVisible('');
            setVisibility(false);
        }
    }

    const searchRef = (reference) => {
        let ticketDetailsObject = {
            reference: reference ? reference : "",
            ticketId: 0,
            userToken: localStorage.getItem("token"),
        };
        axios
            .post(getTicketDetails, ticketDetailsObject)
            .then((response) => {
                if (response.data.status === -1) {
                    setClosedPoolPopup(true);
                }
                else if (response.data.status === 0) {
                    setTicketDetails(response.data.matches);
                }
            })
            .catch((e) => {
                console.log(e);
                setInvalidRefPopup(true);
            });
    }

    return (
        <div className="game-container__wrapper">
            <div className='matchList__promo_section'>
                <Betslip activeButtonsList={props.activeButtonsList} poolItem={poolDetails ? poolDetails : ticketDetails ? ticketDetails.pool : ''} searchRef={searchRef} />
                <Link to="/promo"><img className='promo_banner' src={banner} alt='Promo Portrait' /></Link>
            </div>
            <div className="game-container__wrapper-pools">
                <div className='matchList-container'>
                    {poolDetails ?
                        <div className={'content-container ' + pagevisible}>
                            <Link to='/'>
                                <span className='matchList__back-button' onClick={() => { setPoolDetails(null); props.handleclick(false) }}></span>
                            </Link>
                            <div className='matchList__start-date'>
                                <span className='matchList__date-text'>{moment(poolDetails.pool_startdate).format('dddd, d MMMM YYYY')}</span>
                                {
                                    betreference ?
                                        <span className='matchList__date-text savedbet'>you have a saved bet &nbsp;
                                            <a onClick={showSavedBet} className="matchlist__buttonshowbet">
                                                {betreference}  &nbsp;&nbsp;
                                                <FontAwesomeIcon icon={faCopy} className='matchlist__buttoncopy' onClick={() => navigator.clipboard.writeText(betreference)} />
                                            </a>
                                        </span>
                                        : ''
                                }
                            </div>
                            <div className='matchList__header'>
                                <div className='matchList__header__title-matchcount'>
                                    <h1 className='matchList__header__title'>{poolDetails.pool_title}</h1>
                                    <span className='matchList__header__matchcount'>{poolDetails.pool_nb_matches}</span>
                                </div>
                                {inactivePools ?
                                    <span className='matchList__closingDate-text'>Closed</span>
                                    :
                                    <span className='matchList__closingDate-text'>Closing {moment(poolDetails.pool_enddate).format('hh:mm a')}</span>
                                }
                            </div>
                            {poolDetails.matches.map((match, matchIndex) => {
                                return (
                                    <div key={matchIndex}>
                                        <div className='matchList__team-header'>
                                            <div className='matchList__team-section'>
                                                <img className='matchList__team-icon' src={match.homeTeam.mediaList[0] ? match.homeTeam.mediaList[0].url : ''} />
                                                <p className='matchList__team-name'>{match.homeTeam.name}</p>
                                            </div>
                                            <div className='matchList__team-info'>
                                                <span className='matchList__match-text'>Match {match.match_order}</span>
                                                <div className='matchList__team-info statscontainer' onClick={() => handleStatistics(true, match)}>
                                                    <span className='matchList__team-info__spanstats'>statistics</span>
                                                    <FontAwesomeIcon icon={faChartSimple} className='matchList__team-info__iconstats' />
                                                </div>
                                            </div>
                                            <div className='matchList__team-section'>
                                                <img className='matchList__team-icon' src={match.awayTeam.mediaList[0] ? match.awayTeam.mediaList[0].url : ''} />
                                                <p className='matchList__team-name'>{match.awayTeam.name}</p>
                                            </div>
                                        </div>
                                        <div className={'matchList__match-options'}>
                                            {poolDetails.pool_betypes_settings ?
                                                btn_active ?
                                                    poolDetails.pool_betypes_settings.map((setting, settingIndex) => {
                                                        return (
                                                            <button key={matchIndex + ', ' + settingIndex} className={'matchList__btn_match-option ' + (inactivePools ? 'matchList__btn_match-option--disabled' : (btn_active[matchIndex][settingIndex] == '' ? '' : 'matchList__btn_active'))} onClick={() => { handleChange(matchIndex, settingIndex) }} disabled={inactivePools}>
                                                                {inactivePools ? <i className="fa fa-lock matchList__btn_match-option--lock" /> : <></>}
                                                                {poolDetails.pool_betypes_settings[settingIndex].name}
                                                            </button>
                                                        );
                                                    })
                                                    :
                                                    <></>
                                                :
                                                <></>
                                            }
                                        </div>
                                        <div className='matchList__matches-seperator' />
                                    </div>
                                )
                            })
                            }
                            {loader}
                        </div>
                        :
                        <></>
                    }
                    <div className={visibility ? "statisticsvisible" : "statsvisible-no"}>
                        <Statistics handleclick={handleStatistics} match={matchstats} />
                    </div>
                </div>
            </div>

            {closedPoolPopup ?
                <Popup title="Pool Closed" data="Please enter a valid pool bet!" options={["OK"]} showPopup={setClosedPoolPopup} />
                :
                <></>
            }
            {invalidRefPopup ?
                <Popup title="Invalid Reference" data="Please enter a valid reference!" options={["OK"]} showPopup={setInvalidRefPopup} />
                :
                <></>
            }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        activeButtonsList: state.auth.activeButtonsList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateActiveButtons: (btn_active) => dispatch(actions.updateActiveButtons(btn_active))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchesList);