import { useEffect, useState } from 'react';
import { getTicketsByUserToken } from '../../Api';
import './MyBets.css';
import axios from 'axios';
import moment from 'moment';
import Loader, { ErrorMessage } from '../../components/Loader/Loader';
import ReactGA from "react-ga4";
import BetDetails from '../../components/MyBets/BetDetails/BetDetails';

export default function MyBets(props) {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/bethistory" });
    }, []);

    const [loader, setLoader] = useState(null);

    const userTokenObject = {
        userToken: localStorage.getItem("token")
    }

    const [active, setActive] = useState([]);
    const [tickets, setTickets] = useState(null);
    const [ticketGroupArrays, setTicketGroupArrays] = useState(null);

    useEffect(() => {
        getUserTickets(0);
    }, []);

    useEffect(() => {
        if (tickets) {

            const ticketGroups = tickets.reduce((ticketGroups, ticket) => {
                const date = moment(ticket.creationDate).format('dddd, d MMMM YYYY');
                if (!ticketGroups[date]) {
                    ticketGroups[date] = [];
                }
                ticketGroups[date].push(ticket);
                return ticketGroups;
            }, {});

            setTicketGroupArrays(Object.keys(ticketGroups).map((date) => {
                return {
                    date: date,
                    tickets: ticketGroups[date]
                };
            }));
        }

    }, [tickets]);

    const getUserTickets = (isResulted) => {
        setTicketGroupArrays(null);
        setLoader(Loader);
        let url = getTicketsByUserToken(isResulted);
        axios.post(url, userTokenObject).then((response) => {
            if (response.data) {
                setTickets(response.data.tickets);
            }
            setLoader(null)

        }).catch((e) => {
            setLoader(ErrorMessage());
        })

        isResulted ? setActive(['', 'myBets-navMenuItem__active']) : setActive(['myBets-navMenuItem__active', '']);
    }

    return (
        <div className="tabs-content">
            <ul className="myBets-navMenu">
                <li key={0} className={"myBets-navMenuItem " + active[0]} onClick={() => getUserTickets(0)}>
                    Live Bets
                </li>
                <li key={1} className={"myBets-navMenuItem " + active[1]} onClick={() => getUserTickets(1)}>
                    Past Bets
                </li>
            </ul>

            {ticketGroupArrays ?
                ticketGroupArrays.length > 0 ? ticketGroupArrays.map((ticketGroup, ticketGroupIndex) => {
                    return (
                        <div key={ticketGroupIndex}>
                            <div className='myBets__betDate'>
                                {ticketGroup.date}
                            </div>
                            {ticketGroup.tickets.map((ticket, ticketIndex) => {
                                return (
                                    <BetDetails key={ticketIndex} ticket={ticket} />
                                );
                            })}
                        </div>
                    );
                }) : <>
                    <div className='myBets__betDate'>
                        No bets to display
                    </div>
                </>
                :
                <></>
            }

            {loader}

        </div>
    );
}