import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FootComponent from "../components/FootComponent";
import HeaderComponent from "../components/HeaderComponent";
import { Outlet } from "react-router-dom"

const Layout = () => {
    const [activeTab, setActiveTab] = useState(1);
    const location = useLocation();
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('data') === "security") {
            setActiveTab(2);
        }
    }, []);

    return (<>
        <HeaderComponent />

        <section id="site_body">
            <div className="grid">
                <div className="s_main_body">
                    <div className="info_box">
                        <div id="container">
                            {location?.pathname?.includes("/profile") &&
                                <article>
                                    <div className="tabs-nav">
                                        <ul className="tabs_desktop">
                                            <li className={activeTab === 1 ? 'active' : ''}>
                                                <a href="/profile?data=info"><h4>Basic Information</h4></a></li>
                                            <li className={activeTab === 2 ? 'active' : ''}><a href="/profile?data=security"><h4>Account Security</h4></a></li>
                                        </ul>
                                        <ul className="tabs_mobile">
                                            <li className={activeTab === 1 ? 'active' : ''}>
                                                <a href="/profile?data=info">
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                        viewBox="0 0 600 600" xmlSpace="preserve">

                                                        <g>
                                                            <g>

                                                                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -124.5482 299.9273)" className="info" cx="299.77" cy="300.31" rx="295.14" ry="295.14" />
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <path className="info-1" d="M300.69,275.87c27.09,0,52.12-13.2,68.1-33.35c11.81-14.59,18.76-33.35,18.76-54.21
                c0-47.95-38.92-87.56-86.87-87.56c-48.64,0-87.56,39.62-87.56,87.56c0,20.85,6.94,39.62,18.76,54.21
                C247.88,262.67,272.89,275.87,300.69,275.87z"/>
                                                            </g>
                                                            <path className="info-1" d="M454.55,431.89c0,55.26-308.56,55.26-308.56,0l0.1-64.39c12.19-88.03,283.39-91.73,308.53,0L454.55,431.89z" />
                                                        </g>
                                                    </svg>
                                                </a>
                                            </li>
                                            <li className={activeTab === 2 ? 'active' : ''}>
                                                <a href="/profile?data=security">
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                        viewBox="0 0 600 600" xmlSpace="preserve">

                                                        <g>
                                                            <g>

                                                                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -124.5482 299.9273)" className="security-1" cx="299.77" cy="300.31" rx="295.14" ry="295.14" />
                                                            </g>
                                                            <g>
                                                                <path className="security-2" d="M157.71,246.9v208.98c0,23.26,284.13,23.26,284.13,0V246.9H157.71z M314.14,357.88v59.23
                c0,7.89-6.42,14.44-14.31,14.44c-8.02,0-14.44-6.55-14.44-14.44v-59.36c-12.17-5.48-20.72-17.78-20.72-31.96
                c0-19.39,15.78-35.03,35.16-35.03c19.39,0,35.03,15.64,35.03,35.03C334.87,340.09,326.44,352.4,314.14,357.88z"/>
                                                                <path className="security-2" d="M416.96,232.01H388.2v-14.07c0-48.76-39.67-88.43-88.42-88.43c-48.76,0-88.43,39.67-88.43,88.43v14.07H182.6
                v-14.07c0-64.62,52.57-117.19,117.19-117.19c64.61,0,117.18,52.57,117.18,117.19V232.01z"/>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </article>
                            }

                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </section >
        <FootComponent />
    </>);
}

export default Layout;