import { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

const Index = () => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/welcome" });
    }, [])

    return (<>
        <div className="control-box">
            <div className="rads">
                <Link to="/" id="logo"></Link>
                <div className="aut-box">
                    <Link to="/register"><button>Sign up</button></Link>
                    <Link to="/login"><button className="_c-signin">login</button></Link></div>
            </div>
        </div>
        <footer>&copy; 2022 Footycash. All rights reserved.</footer>
    </>);
}

export default Index;