import { useEffect } from "react";
import ReactGA from "react-ga4";

const Privacy = () => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/privacy" });
    }, [])

    return (
        <>
            <h3>Privacy Policy</h3>
            <div className="l-page-content">
                <p>Footycash website, strongly believe in protecting the confidentiality and security of
                    information we
                    collect about individuals. The material below describes our privacy policy how we treat the
                    information we
                    receive about you if you interact with any of our sites.
                </p>
                <p>Why We Need to Know About You We need to know about you so that we can provide you with
                    information on
                    products and services and better target our sports content offering to your interests. What
                    we need to
                    know about you includes your contact number, age and preferences where necessary. All data
                    about you
                    collected are processed following data protection principles of accountability, lawfulness,
                    specification
                    of purpose, compatibility of further processing with the purpose of collection, quality of
                    information,
                    openness, data security safeguards and data subject (your) participation.
                </p>
                <p>How We Learn About You What we know about you we get mostly from you. But we may also have to
                    find out
                    more about you from other sources in order to make sure that what we know about you is
                    correct and
                    complete. We do not collect personally identifiable information about you except when you
                    specifically
                    provide such information on a voluntary basis. For example, such personally identifiable
                    information may
                    be gathered from competitions, message boards, suggestions, social media sites and
                    transactional areas.
                </p>
                <p>Your Right to Opt Out: At our points of data collection, you have the opportunity to indicate
                    whether you
                    would like to "opt in" or "opt out" of receiving promotional and/or marketing information
                    about other
                    products, services and offerings from group subsidiaries and/or third parties on whose
                    behalf we may send
                    out information. </p>
                <p>Further, notwithstanding any opt out of promotional information by the user, we reserve the
                    right to
                    contact you regarding account status, changes to our service agreement and other matters
                    relevant to the
                    underlying service and/or the information collected. You can request to "opt out" of further
                    promotional
                    contacts at any time by engaging us through the contact us page or by simply not checking
                    the promotional
                    campaign box upon signing up. </p>
                <p>Also, upon request, we will use commercially reasonable efforts functionally to delete you
                    and your
                    personal information from our database; however, it may be impossible to delete all your
                    entry without
                    some residual information due to backups and records of deletions.
                    How We Protect What We Know About You: We treat what we know about you confidentially. Our
                    employees take
                    care in handling your information.</p>
                <p> They may get information about you only when there is a good reason to do so. We take steps
                    to make our
                    databases secure and to safeguard the information we have about you.
                    How We Use and Disclose What We Know About You
                    We may use anything we know about you to help us serve you better. </p>
                <p>We will not disclose your information to any third party except for the following:
                </p>
                <p>• To help us process questions you may have about a purchase, registration or other inquiry
                    you may have.
                </p>
                <p>• To confirm or correct what we know about you.</p>
                <p> • To help us or law enforcement agencies prevent fraud, money laundering, terrorism and
                    other crimes.
                </p>
                <p>• To allow for verification of what we know about you.
                </p>
                <p>• To meet data processing requirements.
                </p>
                <p>• Assisting an agency perform research for us.
                </p>
                <p>• Compliance with the law or request of a government agency for example, complying with a
                    search warrant.
                </p>
                <p>• Compliance with an audit for our business.
                </p>
                <p>• If we sell all or any part of our business or merge with another company.
                    Generally, we will disclose only the information we consider reasonably necessary to
                    disclose.
                </p>
                <p>How You Can See and Correct Your Information Generally, we will let you review what we know
                    about you if
                    you ask us in writing or email. If you tell us that what we know about you is incorrect, we
                    will review
                    it.</p>
                <p> If we agree with you, we will correct our records.
                    Third Party Data Collection You should be aware that sites within the group might sometimes
                    permit third
                    parties to offer you subscription, registration-based services, promotions or competitions
                    through our
                    network. We are not responsible for any actions or policies of such third parties and users
                    should check
                    the applicable privacy policy of such party when providing personally identifiable
                    information to other
                    parties.
                </p>
                <p>Sharing Your Data in Public Areas of our Sites You should be aware that when you voluntarily
                    disclose
                    personally identifiable information (e.g. user name, e-mail address) on any of our message
                    boards, that
                    information, along with any substantive information disclosed in your communication, can be
                    collected and
                    correlated and used by third parties and may result in unsolicited messages from other
                    posters or third
                    parties. </p>
                <p>Such activities are beyond our control.
                    How We Use IP Addresses We may use your IP address to help diagnose any problems with our
                    server or to
                    administer our websites. </p>
                <p>Your IP address may be used to help identify you and to gather broad demographic information
                    that helps
                    us understand user requirements. </p>
                <p>This specific information will only be used within our organization and we will only share or
                    use this
                    information on an aggregate basis.
                    How We Use Cookies We collect non-personal information and data through the standard
                    operation of our
                    servers or through the use of cookies. </p>
                <p>Cookies are small text files a web site used to recognize repeat users, facilitate your
                    ongoing access to
                    and use of the site, track usage, follow site behavior and compile aggregate data that will
                    allow content
                    improvements and targeted advertising. </p>
                <p>Cookies are not programs that come onto your system and damage files. Generally, cookies work
                    by
                    assigning a unique number to the user that has no meaning outside the assigning site.
                    Cookies can help you
                    for example by delivering content specific to your interests, or to save your password so
                    you don't have
                    to re-enter it each time you visit our site. </p>
                <p>Our sites employ the services of an outside companies to serve and appropriately target
                    online
                    advertisements and track site usage. To do this, their software collects anonymous data
                    through the use of
                    cookies. Users should be aware that we cannot control the use of cookies or the resulting
                    information by
                    advertisers or third parties hosting data for us. </p>
                <p>If you do not want information collected through the use of cookies, there is a simple
                    procedure in most
                    browsers that allows you to deny or accept the cookie feature; however, you should note that
                    cookies may
                    be necessary to provide the user with certain features (e.g. customized delivery of
                    information) available
                    on our web sites.
                    Links to Other Sites Our site contains links to other sites outside of our control. We are
                    not responsible
                    for the content of those websites.
                </p>
                <p>Footycash.com.gh knows that you care about how your personal information is used and shared,
                    and we take
                    your privacy very seriously. Please read the following to learn more about our privacy
                    policy. </p>
                <p>By visiting our website, you are accepting the practices outlined in this Privacy Policy.
                    This Privacy
                    Policy covers Footycash.com 's treatment of personal information that Footycash.com gathers
                    when you are
                    on the website and when you use our services.</p>
                <p> This policy does not apply to the practices of third parties that Footycash.com does not own
                    or control,
                    or to individuals that Footycash.com.gh does not employ or manage.
                    Information Collected by Footycash.com.gh We only collect personal information that is
                    relevant to the
                    purpose of our website. This information allows us to provide you with a customized and
                    efficient
                    experience. We do not process this information in a way that is incompatible with this
                    objective. </p>
                <p>We collect the following types of information from our Footycash.com.gh users
                </p>
                <p>1. Information You Provide to Us: We receive and store any information you enter on our
                    website or
                    provide to us in any other way. You can choose not to provide us with certain information,
                    but then you
                    may not be able to take advantage of many of our special features.
                </p>
                <p>2. Automatic Information: We receive and store certain types of information whenever you
                    interact with
                    us. Footycash.com and its authorized agents automatically receive and record certain
                    "traffic data" on
                    their server logs from your browser including your IP address, Footycash.com cookie
                    information, and the
                    page you requested.</p>
                <p> Footycash.com.gh uses this traffic data to help diagnose problems with its servers, analyze
                    trends and
                    administer the website.</p>
                <p> Footycash.com.gh may collect and on any page, display the total counts that page has been
                    viewed.
                    E-mail Communications Footycash.com is very concerned about your privacy and we will never
                    provide your
                    email address to a third party without your explicit permission, as detailed in the "Sharing
                    Your
                    Information" section below. Footycash.com may send out e-mails with Footycash.com related
                    news, products,
                    offers, surveys or promotions.
                </p>
                <p>Cookies: Cookies are alphanumeric identifiers that we transfer to your computer's hard drive
                    through your
                    Web browser to enable our systems to recognize your browser and tell us how and when pages
                    in our website
                    are visited and by how many people. Footycash.com.gh cookies do not collect personal
                    information, and we
                    do not combine information collected through cookies with other personal information to tell
                    us who you
                    are or what your screen name or e-mail address is.</p>
                <p> The "contact" portion on our website will direct you on how to prevent your browser from
                    accepting new
                    cookies, how to command the browser to tell you when you receive a new cookie, or how to
                    fully disable
                    cookies upon engagement with our team. </p>
                <p>We however recommend that you leave the cookies activated because cookies allow you to use
                    some of
                    Footycash.com 's coolest features. </p>
                <p>Footycash.com.gh's advertising partners may place a cookie on your browser that makes it
                    possible to
                    collect anonymous non-personally identifiable information that ad delivery systems use to
                    present more
                    relevant ads. If you would prefer to opt-out of this standard practice, please reach out to
                    us through our
                    contact us page on this website. </p>
                <p>Sharing Your Information Rest assured that we neither rent nor sell your personal information
                    to anyone
                    and that we will share your personal information only as described below. </p>
                <p>Footycash.com.gh Personnel: Footycash.com.gh personnel and authorized consultants and/or
                    contractors may
                    have access to user information, if necessary, in the normal course of Footycash.com.gh
                    business. </p>
                <p>Business Transfers: In some cases, we may choose to buy or sell assets. In these types of
                    transactions,
                    user information is typically one of the business assets that is transferred. </p>
                <p>Moreover, if Footycash.com.gh, or substantially all of its assets, were acquired, user
                    information would
                    be one of the assets that is transferred. </p>
                <p>Protection of Footycash.com and Others: We may release personal information when we believe
                    in good faith
                    that release is necessary to comply with a law; to enforce or apply our Terms of Use and
                    other policies;
                    or to protect the rights, property, or safety of Footycash.com, our employees, our users, or
                    others. </p>
                <p>This includes exchanging information with other companies and organizations for fraud
                    protection and
                    credit risk reduction.
                    Syndication: Footycash.com .ghallows for the really Simple Syndication (RSS) of all of its
                    public content
                    within the Footycash.com website. </p>
                <p>With Your Consent: Except as noted above, we will contact you when your personal information
                    is shared
                    with third parties or used for a purpose incompatible with the purpose(s) for which it was
                    originally
                    collected, and you will be able to opt out to prevent the sharing of this information.
                    Children Under 18 Years of Age: You must be 18 years and older to register to use the
                    Footycash.com
                    website. As a result, Footycash.com does not specifically collect information about
                    children.</p>
                <p> If we learn that Footycash.com has collected information from a child under the age of 18,
                    we will
                    delete that information as quickly as possible. We recommend that minors below the age of 18
                    ask and
                    receive their parents' permission before using Footycash.com or sending information about
                    themselves or
                    anyone else over the Internet. </p>
                <p>Changes to this Privacy Policy: Footycash.com may amend this Privacy Policy from time to
                    time, at its
                    sole discretion. Use of information we collect now is subject to the Privacy Policy in
                    effect at the time
                    such information is used.</p>
                <p> If we make changes to the Privacy Policy, we will notify you by posting an announcement on
                    the
                    Footycash.com.gh website so you are always aware of what information we collect, how we use
                    it, and under
                    what circumstances if any, it is disclosed. </p>
                <p>Conditions of Use: If you decide to visit Footycash.com.gh website, your visit and any
                    possible dispute
                    over privacy is subject to this Privacy Policy and our Terms of Use, including limitations
                    on damages,
                    arbitration of disputes, and application of Ghana law. </p>
                <p>Effective Date of this Privacy Policy This Privacy Policy is effective as of 24th November,
                    2022 and
                    subject to subsequent updates </p>
            </div>
        </>
    );
}

export default Privacy;