import { useEffect, useState } from "react";
import './BetMoreDetails.css';
import ball from '../../../assets/images/ball.png';

export default function BetMoreDetails(props) {

    const [btnStatus, setBtnStatus] = useState(props.ticketDetails ? (Array(props.ticketDetails.matches.length).fill('').map(row => new Array(props.ticketDetails.pool_betypes_settings.length).fill(''))) : null);

    useEffect(() => {

        let colorStyle = '';

        if (props.ticketDetails) {
            let copy = Array(props.ticketDetails.matches.length).fill('').map(row => new Array(props.ticketDetails.pool_betypes_settings.length).fill(''));

            props.ticketDetails.matches.map((match, matchId) => {
                match.betselection.split(',').map((betChoice, betChoiceId) => {
                    props.ticketDetails.pool_betypes_settings.map((setting, settingId) => {
                        if (betChoice == setting.order) {
                            if (match.winningBet == 1) {
                                colorStyle = 'betMoreDetails__btn_match-option--green';
                            }
                            else if (match.matchScore == 'Not Played') {
                                colorStyle = 'betMoreDetails__btn_match-option--yellow';
                            }
                            else {
                                colorStyle = 'betMoreDetails__btn_match-option--red';
                            }
                            if (btnStatus) {
                                copy = [...btnStatus];
                            }
                            copy[matchId][settingId] = colorStyle;
                            setBtnStatus(copy);
                        }
                    })
                })
            })

        }
    }, [props.ticketDetails]);

    return (
        <div className={"betMoreDetails-container " + (props.visible ? props.visible : '')}>
            {(props.ticketDetails) ?
                props.ticketDetails.matches.map((match, matchIndex) => {
                    return (
                        <div key={matchIndex}>
                            <div className='betMoreDetails__team-header'>
                                <div className='betMoreDetails__team-section'>
                                    <p className='betMoreDetails__team-name'>{match.homeTeam.name}</p>
                                </div>
                                <span className='betMoreDetails__match-text'>{match.matchScore}</span>
                                <div className='betMoreDetails__team-section'>
                                    <p className='betMoreDetails__team-name'>{match.awayTeam.name}</p>
                                </div>
                            </div>
                            <div className={'betMoreDetails__match-options '}>
                                {props.ticketDetails.pool_betypes_settings ?
                                    btnStatus ?
                                        props.ticketDetails.pool_betypes_settings.map((setting, settingIndex) => {
                                            return (
                                                <button key={matchIndex + ', ' + settingIndex} className={'betMoreDetails__btn_match-option ' + btnStatus[matchIndex][settingIndex]} >
                                                    <div hidden={match.resultBetShortName == setting.short_name ? false : true} className='betMoreDetails__ball' >
                                                        <img src={ball} />
                                                    </div>
                                                    {props.ticketDetails.pool_betypes_settings[settingIndex].name}
                                                </button>
                                            );
                                        })
                                        :
                                        <></>
                                    :
                                    <></>
                                }
                            </div>
                            <div className='betMoreDetails__matches-seperator' />
                        </div>
                    )
                })
                : <></>}
        </div>
    );

}