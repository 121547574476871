import "./Loader.css"

export default function Loader() {
	return (
		<div className="loader_container" />
	);
}

export function ErrorMessage(message) {
	return (
		<div className="error_message_container">
			{message ? message : 'Error retrieving data!'}
		</div>
	);
}
