import { useEffect, useState } from 'react'
import "./Previous.css"

export default function Previous(props) {
	const [losewinstate, setLoseWinState] = useState(null);
	const [containerstate, setContainerState] = useState(null);

	useEffect(() => {
		if (props.latestresult) {
			setContainerState('scorecontainer')
			if (props.venue === 'H') {
				if (props.score1 < props.score2) {
					setLoseWinState('loosingteam');
				} else if (props.score1 > props.score2) {
					setLoseWinState('winningteam');
				} else {
					setLoseWinState('drawteams');
				}
			} else if (props.venue === 'A') {
				if (props.score1 > props.score2) {
					setLoseWinState('loosingteam');
				} else if (props.score1 < props.score2) {
					setLoseWinState('winningteam');
				} else {
					setLoseWinState('drawteams');
				}
			}
		} else
			setContainerState('noscorecontainer')
	}, [props.latestresult, props.venue, props.score1, props.score2])

	return (
		<div className="previous-container">
			<div className="previous-container-date dataprevious">
				<span>{props.date}</span>
			</div>
			{
				props.league ?
					<div className="previous-container-league dataprevious">
						<span >{props.league}</span>
					</div> : ''
			}
			<div className="previous-container-firstteam dataprevious">
				<span>{props.team1}</span>
			</div>
			<div className="previous-container-scoreboard dataprevious">
				<div className={containerstate + " " + losewinstate}>

					<span className="previous-container-scoreboard-team1">{props.score1}</span>
					-
					<span className="previous-container-scoreboard-team2">{props.score2}</span>
				</div>
			</div>
			<div className="previous-container-secondteam dataprevious">
				<span >{props.team2}</span>
			</div>
			{props.venue ?
				<div className="previous-container-winningletter dataprevious">
					<span >{props.venue}</span>
				</div> : ''
			}

		</div>
	)
}
