import { useEffect } from "react";
import ReactGA from "react-ga4";

const About = () => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/about" });
    }, [])

    return (
        <>
            <h3>About us</h3>
            <div className="l-page-content"><p>Footy Cash is operated by Keed Ghana Ltd., an indigenous technology company operating in Ghana, with an official partnership with the Ghana Football Association (GFA).</p>

                <p>Our team is made up of technology enthusiasts who are ready to meet innovation requirements in fields particularly in the gaming and instant gratification industries.</p>

                <p>The partnership with the GFA guarantees that a percentage of the proceeds from Footy Cash will be reinvested back into the game of football domestically to raise the appeal for our local leagues.</p>

                <p>Our vision is that the Footy Cash game grows in magnitude to help position the local game as one that is enviable and of world class standard.</p>
            </div>
        </>);
}

export default About;