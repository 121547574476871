import { useState, useEffect } from 'react';
import './Poolsdetails.css';
import axios from 'axios';
import Historymatches from "../historymatches/Historymatches";
import image from "../../assets/images/unkown-avatar.jpg"
import { getPoolDetailsApi, getPoolStatsApi } from '../../Api';
import Loader, { ErrorMessage } from '../Loader/Loader';

export default function Poolsdetails(props) {

    const [loader, setLoader] = useState(null);
    const [matches, setmatches] = useState([]);

    const [totalPayout, setTotalPayout] = useState(null);
    const [userPayout, setUserPayout] = useState(null);
    const [winnersCount, setWinnersCount] = useState(null);

    const getDetails = () => {
        if (props.poolId) {
            setLoader(Loader);
            let url = getPoolDetailsApi(props.poolId);
            axios.get(url)
                .then((response) => {
                    setmatches(response.data.matches);
                    setLoader(null);
                })
                .catch(() => {
                    setLoader(ErrorMessage());
                })
        }
    }

    const getPoolStats = (poolId) => {
        if (poolId) {
            let url = getPoolStatsApi(poolId);
            axios.get(url)
                .then((response) => {
                    if (response?.data?.status === 0) {
                        setTotalPayout(response?.data?.totalPayout);
                        setUserPayout(response?.data?.userPayout);
                        setWinnersCount(response?.data?.numberOfWinners);
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                })
        }
    }

    useEffect(() => {
        if (props.extendinfo === "showinfo") {
            getDetails();
        }
        if (props.poolId) {
            getPoolStats(props.poolId);
        }
    }, [props.extendinfo])

    return (
        <>

            <div className="detailspool-container__date">
                {props.date}
            </div>
            <div className={props.extendinfo ? "detailspool-container__arrow openarrowdown" : "detailspool-container__arrow open"} />

            <div className="matchdetails-wrapper">

                <div className="details-header">
                    <div>home team</div>
                    <div>vs</div>
                    <div>away team</div>
                </div>

                {matches ? matches.map((item, index) => {
                    return (
                        <Historymatches key={index} hometeam={item.homeTeam.name} awayteam={item.awayTeam.name} hometeamscore={item.homeTeam.score_home} awayteamscore={item.awayTeam.score_away} awayteamphoto={item.awayTeam.mediaList[0] ? item.awayTeam.mediaList[0].url : image} hometeamphoto={item.homeTeam.mediaList[0] ? item.homeTeam.mediaList[0].url : image} />
                    )
                })
                    :
                    <></>
                }

                {loader}
            </div>
            {totalPayout || userPayout || winnersCount ?
                <div className="detailspool-container__pool-stats">
                    {totalPayout ? <span>Total Payout: {totalPayout}</span> : ''}
                    {winnersCount ? <span>Winners Nb: {winnersCount}</span> : ''}
                    {userPayout ? <span>Total User Payout: {userPayout}</span> : ''}
                </div>
                :
                <></>
            }
        </>
    )
}

