import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LogoComponent from "../components/LogoComponent";
import axios from 'axios';
import Spinner from "../components/Spinner";
import ErrorDisplay from "../components/ErrorDisplay";
import ReactGA from "react-ga4";

const Index = () => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/login" });
    }, [])

    const [showpass, setShowPass] = useState(false);
    const [payload, setPayload] = useState({
        mobileNumber: "",
        password: "",
        type: 1
    });
    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(false);

    const handleOnChange = (event) => {
        const newValue = {};
        newValue[event.target.name] = event.target.value;
        setPayload({ ...payload, ...newValue });
    }

    const handleOnSubmit = () => {
        const errors = Object.keys(payload)
            .map((input) => payload[input] === "" ? input : true)
            .filter((f) => f !== true);

        if (errors.length)
            setErr(`${errors[0]} is required`);
        else {
            setLoading(true);

            const authData = {
                password: payload.password,
                type: payload.type
            };

            axios.post(`user-api/users/${payload.mobileNumber}`, authData)
                .then((response) => {
                    setErr(response?.data?.message);

                    if (response?.data?.status === 1) {
                        localStorage.setItem("IsLoggedIn", "true");
                        localStorage.setItem("setUserData", JSON.stringify(response?.data?.user));
                        localStorage.setItem("token", response?.data?.token);
                        window.location.href = '/';
                    }
                }).catch((err) => {
                    setErr(err.message);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }

    return (<>
        <div className="cards_brand"><Link to="/" className="rans_logo"></Link></div>
        <div className="control-box">
            <div className="rads" id="login">
                <div className="login_auth"><h1>welcome back</h1><p>Login to your account</p></div>
                {err && <ErrorDisplay props={err} />}

                <div className="aut-box">

                    <LogoComponent />
                    <input type="tel" className="tel" placeholder="Mobile Number"
                        value={payload['mobileNumber']} name="mobileNumber" onChange={(e) => handleOnChange(e)} />

                    <input type={!showpass ? 'password' : 'text'} placeholder="Password" id="password" className="masked" name="password"
                        value={payload['password']} onChange={(e) => handleOnChange(e)} />
                    <span className="eye-icon" onClick={() => setShowPass(!showpass)}><i className={`fa ${showpass ? 'fa-eye-slash' : 'fa-eye'}`} id="eye"></i></span>
                    {!loading ?
                        <button className="_c-signin" onClick={() => handleOnSubmit()}>login</button> :
                        <div style={{ textAlign: 'center' }}><Spinner fill="#fff" bg="rgba(0,0,0,0)" /></div>}

                    <div className="small-text"><p><a href="/recovery">forgot your password?</a></p></div>
                    <div className="small-text"><p><a href="/register">Don't have an account? Register</a></p></div>
                </div>


            </div>
        </div>
        <footer>&copy; 2022 Footycash. All rights reserved.</footer>
    </>);
}

export default Index;